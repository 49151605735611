<template>
    <div>
        <div class="relative pt-10 bg-gradient-to-b from-gray-50 to-white">
            <div class="container">
                <div class="md:grid md:gap-5 md:grid-cols-12">
                    <div class="md:col-span-8 md:col-start-3 lg:col-span-10 lg:col-start-2">
                        <div class="title-b center no-desc">
                            <h2 class="h1 pb-1 capitalize center">Get yout Instant relief now!</h2>
                        </div>
                    </div>
                </div>
                <div class="md:grid md:grid-cols-12 md:gap-5 mobile:mt-8 mobile:flex flex-col products-grid">
                    <!-- :class="{mostPopular : product.amount == 5 -->
                    <div v-for="(product, index) in products" v-bind:key="product.id"
                         v-on:click="changeActiveProduct(product)"
                         :class="'md:col-span-'+(12 / products.length) +' mobile:order-2'">
                        <label class="product-card" @click.prevent="displayCCForm()">
                            <input type="radio" name="m-type">
                            <span v-if="activeProduct.id == product.id"
                                  class="absolute -m-[1] block inset-0 border-[3px] border-primary rounded-[5px]"></span>
                            <span v-else
                                  class="absolute -m-[1] block inset-0 hover:border-[3px] hover:border-[#8568C5] ease-in duration-75 rounded-[5px]"></span>
                            <span v-if="product.status == 'best seller'" id="bestSeller"
                                  class="bg-blue-500 flex items-center justify-center font-semibold absolute w-[119px] h-8 md:w-[182px] md:h-[42px] -mt-4 md:mt-[-21px] mx-auto inset-x-0 top-0 rounded-lg text-white uppercase mobile:text-xs">
    							<svg width="18" height="18"
                                     class="fill-current text-yellow mr-2 md:mr-4 mobile:w-[10px]">
    								<use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
    							</svg>
    							Best seller
    						</span>
                            <span v-if="activeProduct.id == product.id" class="title active">
    							{{ product.amount }} x {{ config.title }}
    						</span>
                            <span v-else="activeProduct.id == product.id" class="title">
    							{{ product.amount }} x {{ config.title }}
    						</span>
                            <span class="qnt">
    							{{ product.amount }}x
    						</span>
                            <picture>
                                <source media="(min-width: 768px)"
                                        :data-srcset="'/home_pp2_assets/images/pd' +product.amount+ '.webp 1x, /home_pp2_assets/images/pd' +product.amount+ '@2x.webp 2x'"
                                        type="image/webp">
                                <source media="(min-width: 768px)"
                                        :data-srcset="'/home_pp2_assets/images/pd' +product.amount+ '.png 1x, /home_pp2_assets/images/pd' +product.amount+ '@2x.png 2x'"
                                        type="image/webp">
                                <source media="(max-width: 767px)"
                                        data-srcset="/home_pp2_assets/images/mobile-picker-img@1x.webp 1x, /home_pp2_assets/images/mobile-picker-img@2x.webp 2x"
                                        type="image/webp"/>
                                <source media="(max-width: 767px)"
                                        data-srcset="/home_pp2_assets/images/mobile-picker-img@1x.png 1x, /home_pp2_assets/images/mobile-picker-img@2x.png 2x"
                                        type="image/png"/>
                                <img :data-src="'/home_pp2_assets/images/pd' +product.amount+ '.png'" alt=""
                                     class="lazyload img"
                                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
                            </picture>
                         
                            <span class="save">
    							Save {{ product.you_save_percent }}%
    						</span>
                            <span class="price-w">
    							<span class="del">
    								{{ product.old_price | currency(config.currencySymbol) }}
    							</span>
    							<span class="current" style="line-height: 1;">
    								{{ product.price | currency(config.currencySymbol) }}<span
                                    class="font-semibold text-[10px] md:text-xs lg:text-sm"> /each</span>
    							</span>
    						</span>
                            <span class="mx-auto max-w-full text-center block">
    							<span class="total">
    								Total: {{ product.price * product.amount| currency(config.currencySymbol) }}
    							</span>
    							<span v-if="activeProduct.id == product.id" class="pb-4 block mobile:hidden">
    								<a href="/" @click.prevent="displayCCForm()" class="btn w-full">Order Now</a>
    							</span>
                                <span v-if="activeProduct.id !== product.id" class="pb-4 block mobile:hidden">
    								<a href="/" @click.prevent="displayCCForm()" class="btn w-full">Select</a>
    							</span>
    							<span class="list">
                                    <span class="li text-[#E05554]">
    									<svg width="16" height="16" class="">
    										<use xlink:href="/home_pp2_assets/images/icons/icons.svg#out2"/>
    									</svg>
    									Low Stock
    								</span>
                                    <span class="li text-[#01B157]">
                                    <img src="/swatch/shipping-truck-green.svg" class="absolute left-0 top-1 mobile:w-3 mobile:h-3 w-[16px] h-[16px]" alt="Free Delivery">
    									Fast Delivery
    								</span>
    								<span class="li capitalize text-[#111619]">
    									<svg width="16" height="16" class="text-lime">
    										<use xlink:href="/home_pp2_assets/images/icons/icons.svg#star2"/>
    									</svg>
    									{{ product.status }}
    								</span>
    							</span>
    						</span>

                            <span v-if="activeProduct.id == product.id"
                    class="hidden mt-[10px] relative  w-full mobile:inline-block mobile:flex justify-center">
    						<a href="/" @click.prevent="displayCCForm()"
                   class="btn mobile:mt-[15px] mobile-xs:max-w-[85%] mobile:max-w-[350px] mobile:w-[100%]  text-center  font-[700] text-[18px] leading-[22px] px-[30px] py-[10px]"
                   style="text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);">
                                <span>Order Now</span>
                            </a>
    					</span>


                            <span v-if="activeProduct.id !== product.id"
                    class="hidden mt-[10px] relative  w-full mobile:inline-block mobile:flex justify-center">
    						<a href="/" @click.prevent="displayCCForm()"
                   class="btn mobile:mt-[15px] mobile-xs:max-w-[85%] mobile:max-w-[350px]  mobile:w-[100%]  text-center  font-[700] text-[18px] leading-[22px] px-[30px] py-[10px]">
                             <span>Select</span>
                            </a>
                        </span>

                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!-- /product -->
        <!-- checkout -->
        <div class="relative overflow-hidden pt-[20px] md:pt-[30px] lg:pt-[20px] pb-10 md:pb-2 lg:pb-8 checkout-section bg-[white]" v-if="showCheckoutSection"
             ref="checkoutSection">
            <div class="container">
                <div class="lg:grid lg:grid-cols-12 lg:gap-14 border-solid border-[#E0E0E0] border-[0.5px] p-[20px] rounded-[20px]" style="">
                    <div class="lg:col-span-6 lg:col-start-1 lg:col-end-7 lg:row-start-1 lg:row-end-2">
                        <div class="h2 tablet:text-center mobile:text-left pb-2 md:pb-6 lg:pb-3 border-b border-gray-200 mobile:mb-3">
                            Shipping Details
                        </div>
                        <div class="md:grid md:grid-cols-12 mb-8 md:mb-6 md:gap-y-3 md:gap-x-5">
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="first_name" type="text" class="form-control" v-model.lazy="first_name"
                                           maxlength="64" :class="inputStatus($v.first_name)" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.first_name )" ref="first_name">
                                    <label>First Name</label>
                                    <span class="error-message" :class="status($v.first_name)">Please enter valid First Name.</span>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="last_name" type="text" class="form-control" v-model.lazy="last_name"
                                           maxlength="64" :class="inputStatus($v.last_name)" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.last_name )" ref="last_name">
                                    <label>Last Name</label>
                                    <span class="error-message" :class="status($v.last_name)">Please enter valid Last Name.</span>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="email" v-model.lazy="email" type="email" inputmode="email"
                                           class="form-control"
                                           maxlength="128" :class="inputStatus($v.email)" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.email )" ref="email" required/>
                                    <label>Email Address</label>
                                    <span class="error-message" :class="status($v.email)">Please enter valid mail address.</span>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="phone" v-model.lazy="phone" type="text" inputmode="tel"
                                           class="form-control pb-[0.75rem]"
                                           maxlength="64" ref="phone" :class="inputStatus( $v.phone )"
                                           @focus="onFocus( $event )" @blur="onBlur( $event, $v.phone )"/>
                                    <span class="error-message"
                                          :class="status( $v.phone )">Enter a valid phone number.</span>
                                </div>
                            </div>
                            <div class="md:col-span-12">
                                <div class="form-group">
                                    <input id="address1" class="form-control" v-model.lazy="address1" type="text"
                                           maxlength="128" :class="inputStatus($v.address1)" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.address1 )" ref="address1">
                                    <label for="address1">Address</label>
                                    <span class="error-message"
                                          :class="status($v.address1)">Please enter valid address.</span>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="city" v-model.lazy="city" type="text" class="form-control"
                                           maxlength="128" :class="inputStatus( $v.city )" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.city )" ref="apt_name">
                                    <label>City</label>
                                    <span class="error-message" :class="status($v.city)">Enter a valid city.</span>
                                </div>
                            </div>
                            <div class="md:col-span-6">
                                <div class="form-group">
                                    <input id="zip" type="text" maxlength="30" ref="zip" class="form-control"
                                           v-model.lazy="zip" :class="inputStatus( $v.zip )" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.zip )">
                                    <label>Postal Code</label>
                                    <span class="error-message" :class="status( $v.zip )">Enter a valid zip code.</span>
                                </div>
                            </div>


                            <div class="md:col-span-6">
                                <div class="form-group" id="state" ref="state">
                                    <region-select
                                        v-model="state" :country="country" :state="state" :placeholder="'Region/State'"
                                        class=" form-control border rounded-md  select px-16px py-20px"
                                        :class="inputStatus( $v.state )" @focus="onFocus( $event )"
                                        @blur="onBlur( $event, $v.state )"/>
                                    <label for="region">Region/State</label>
                                    <span class="error-message absolute" :class="status($v.state)">Please select Your region.</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="h2 tablet:text-center mobile:text-left pb-2 md:pb-6 lg:pb-3 border-b border-gray-200 mobile:mb-3">
                            Payment Information
                            <div class="md:hidden text-xs font-normal pt-2 text-gray-350 font-sans">
                                All transactions are secured and encrypted
                            </div>
                        </div>
                        <!-- <a v-on:click="paypalFunnel = true" v-if="config.paypal_enabled"  class="btn bg-yellow hover:bg-yellow hover:opacity-80 w-full h-[50px] mb-3">
                            <picture>
                                <source data-srcset="/home_pp2_assets/images/paypal.webp 1x, /home_pp2_assets/images/paypal@2x.webp 2x"
                                        type="image/webp"/>
                                <source data-srcset="/home_pp2_assets/images/paypal.png 1x, /home_pp2_assets/images/paypal@2x.png 2x"
                                        type="image/png"/>
                                <img data-src="/home_pp2_assets/images/paypal.webp" alt="" class="lazyload"
                                     src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=">
                            </picture>
                        </a>
                        <div
                            class="text-xs border-b border-gray-200 pb-2 font-normal uppercase text-center text-gray-600 mb-2 md:mb-5">
                            Checkout with credit card
                        </div> -->
                        <div class="md:grid md:grid-cols-12 md:gap-y-3 md:gap-x-5">
                            <div class="md:col-span-12">
                                <div class="form-group">
                                    <img width="37" height="23" :src="getBrandClass(this.cardBrand)"

                                         class="fill-current absolute right-3 inset-y-0 my-auto">
                                    <!-- <svg width="37" height="23"
                                         class="fill-current absolute right-3 inset-y-0 my-auto text-gray-350">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#card"/>
                                    </svg> -->
                                    <input id="card_number" class="form-control pr-16" v-model="card_number" type="tel"
                                           inputmode="numeric"
                                           :class="inputStatus($v.card_number)" @focus="onFocus( $event )"
                                           autocomplete="cc-number"
                                           @blur="onBlur( $event, $v.card_number )" ref="card_number">
                                    <label>Card Number</label>
                                    <span class="error-message"
                                          :class="status($v.card_number)">This field is required.</span>
                                </div>
                            </div>
                            <div class="md:col-span-4">
                                <div class="form-group">
                                    <select required="required" id="month" autocomplete="cc-exp-month"
                                            class="form-control" v-model="card_exp_month"
                                            ref="card_exp_month" :class="inputStatus($v.card_exp_month)">
                                        <option value="-" selected="selected" hidden="hidden" disabled="disabled">MM
                                        </option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <label>Month (MM)</label>
                                    <span class="error-message" :class="status($v.card_exp_month)">This field is required.</span>
                                </div>
                            </div>
                            <div class="md:col-span-4">
                                <div class="form-group">
                                    <select required="required" id="year" autocomplete="cc-exp-year"
                                            class="form-control" v-model="card_exp_year"
                                            ref="card_exp_year" :class="inputStatus($v.card_exp_year)">
                                        <option value="-" selected="selected" hidden="hidden" disabled="disabled">YY
                                        </option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                    </select>
                                    <label>Years (YY)</label>
                                    <span class="error-message" :class="status($v.card_exp_year)">This field is required.</span>
                                </div>
                            </div>
                            <div class="md:col-span-4">
                                <div class="form-group">
                                    <input id="card_cvc" class="form-control pr-12" v-model.lazy="card_cvc" type="tel"
                                           inputmode="numeric" pattern="[0-9]*" minlength="3"
                                           maxlength="4" :class="inputStatus( $v.card_cvc )" @focus="onFocus( $event )"
                                           @blur="onBlur( $event, $v.card_cvc )" ref="apt_name">
                                    <label>CVC</label>
                                    <span class="error-message"
                                          :class="status($v.card_cvc)">This field is required.</span>
                                    <div title="EXTRA TOOLTIP TEXT"
                                         class="absolute inset-y-0 my-auto cursor-help right-4 flex items-center">
                                        <svg @click="cvc_popup = !cvc_popup;" data-target="#cvvModal"
                                             data-toggle="modal" id="reveal" width="18" height="18"
                                             class="fill-current text-gray-350">
                                            <use xlink:href="/home_pp2_assets/images/icons/icons.svg#quest"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="cvv_popup_overlay" @click="cvc_popup = false"
                                 v-bind:class="[cvc_popup == true ? 'open' : '']">
                                <div class="cvv_popup_wrap">
                                    <div class="cvv_popup_content">
                                <span class="close" v-on:click="cvc_popup = false"><i
                                    class="material-icons">clear</i></span>
                                        <h2>Where can I find my Security Code?</h2>
                                        <p v-html="'The security code is a three digit number, located on the back of any\n    Visa&nbsp;<sup>®</sup>&nbsp;or MasterCard&nbsp;<sup>®</sup>&nbsp;on the right side\n    of\n    the\n    signature strip as illustrated.&nbsp;<strong> <br>Last 3 Digits of account number\n        panel:'"></p>
                                        <p><span><img alt="card" width="205" height="120" src="/swatch/card.png"
                                                      class=" b-loaded"></span>
                                        </p>
                                        <p><br><strong>Visa&nbsp;<sup>®</sup>&nbsp;and
                                            MasterCard&nbsp;<sup>®</sup></strong></p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="affiliate == null" class="md:col-span-12">
                                <label class="checkbox mobile:mb-2">
                                    <input id="tos" type="checkbox" required v-model="tos">
                                    <span class="check-label agree">I Have read the
                                        <a href="/page/terms" target="_blank">Terms and Conditions</a> and
                                        <a href="/page/privacy" target="_blank">Privacy Policy</a> and agree with them.
                                    </span>
                                </label>
                                <div class="invalid-feedback my-15px p-8px text-center" :class="status($v.tos)">
                                    You must agree to Terms and Conditions and Privacy Policy.
                                </div>
                            </div>
                        </div>
                        <div v-bind:class="{ hidden: success }" id="card-fields__processing-error"
                             style="background-color: red;color: #fff;font-weight: bold;">

                            <p v-if="errors[0]" style="display:block;padding: 10px;margin:10px; ">
                                {{ errors }}
                            </p>
                        </div>
                        <button @click="submit()" class="btn w-full">Complete Order</button>
                        <div class="py-5 flex justify-center mobile:hidden">
                            <img data-src="/home_pp2_assets/images/secure.svg"
                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                 class="lazyload h-12 object-contain" alt="">
                        </div>
                    </div>
                    <div
              class="h-full w-[0.5px] bg-[#E0E0E0] lg:col-start-1 lg:col-end-13 lg:row-start-1 lg:row-end-2 justify-self-center"></div>
                    <div class="lg:col-span-6 lg:col-start-7 lg:col-end-13 lg:row-start-1 lg:row-end-2 order-summary-container">
                        <div class="order-summary-holder">
                            <div class=" order-summary-top">
                                <div class="absolute inset-0 -my-24 -ml-7 mr-[-50vw] bg-gray-50 z-[-1] tablet:hidden"></div>
                                <div class="h2 tablet:text-xl tablet:py-2 tablet:leading-8 tablet:mb-0 tablet:-mx-5 tablet:px-5 pb-3 border-b border-gray-200"
                                    :class="{ active : showOrderSummary }"
                                    @click="showOrderSummary = !showOrderSummary">
                                    Order Summary
                                    <svg width="11" height="6" class="fill-current arr inline-block ml-1 lg:hidden">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#up"/>
                                    </svg>
                                    <span class="float-right font-sans text-sm text-gray-500 leading-8 font-medium lg:hidden">{{ countTotalPrice() | currency(config.currencySymbol) }}</span>
                                </div>
                                <transition name="slide">
                                    <div v-if="showOrderSummary">
                                        <div class="flex border-b border-gray-200 tablet:py-4 pb-5 mb-4 flex-wrap items-center">
                                            <div class="w-[58px] h-[58px] lg:w-[103px] mr-1 lg:mr-5 relative lg:h-[103px] bg-white border border-gray-200">
                                                <picture>
                                                    <source :src="'/home_pp2_assets/images/pd' +activeProduct.amount+ '.webp 1x, /home_pp2_assets/images/pd' +activeProduct.amount+ '-2x.webp 2x'"
                                                        type="image/webp"/>
                                                    <source :src="'/home_pp2_assets/images/pd' +activeProduct.amount+ '.png 1x, /home_pp2_assets/images/pd' +activeProduct.amount+ '-2x.png 2x'"
                                                        type="image/png"/>
                                                    <img :src="'/home_pp2_assets/images/pd' +activeProduct.amount+ '.webp'"
                                                        alt=""
                                                        class="lazyload object-contain bg"
                                                       >
                                                </picture>
                                            </div>
                                            <div class="pl-4 tablet:text-sm font-normal">
                                                <strong>{{ activeProduct.amount }} x</strong> {{ config.title }}
                                            </div>
                                            <div class="ml-auto md:font-semibold">
                                                {{ activeProduct.price | currency(config.currencySymbol) }}/each
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center mb-3">
                                            <div class="pr-1">
                                                Regular price:
                                            </div>
                                            <div class="pl-1">
                                                {{ activeProduct.old_price | currency(config.currencySymbol) }}
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center mb-3">
                                            <div class="pr-1">
                                                Coupon Applied: <strong>MEGASAVINGS</strong>
                                            </div>
                                            <div class="pl-1">
                                                -{{
                                                    activeProduct.old_price - (activeProduct.price * activeProduct.amount) | currency(config.currencySymbol)
                                                }}
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center mb-3">
                                            <div class="pr-1">Shipping:</div>
                                            <div class="pl-1">
                                                {{ activeProduct.shipping | currency(config.currencySymbol) }}
                                            </div>
                                        </div>
                                        <div
                                            class="flex border-t border-gray-200 pt-3 lg:pt-5 justify-between items-center lg:mb-12 pb-5">
                                            <div class="pr-1">Total:</div>
                                            <div class="pl-1">{{
                                                    countTotalPrice() | currency(config.currencySymbol)
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                                <div class="tablet:hidden">
                                    <div class="border border-gray-200 p-5 mb-5">
                                        <div class="flex items-center mb-2">
                                            <img data-src="/home_pp2_assets/images/gr1.svg"
                                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                                 class="lazyload mr-5 w-13" alt="">
                                            <div class="text-lg text-primary font-semibold">
                                                30-Day Money Back Guarantee
                                            </div>
                                        </div>
                                        <div class="text-sm leading-6 text-gray-500">
                                            <p>If the item wasn’t what you’ve expected, it has been damaged or hasn’t
                                                arrived, and 30 calendar days have not yet passed after the delivery -
                                                please contact our Customer Support team via here, and we will help you
                                                get a full refund ASAP!</p>
                                        </div>
                                    </div>
                                    <div class="border border-gray-200 p-5 mb-5">
                                        <div class="flex items-center mb-2">
                                            <img data-src="/home_pp2_assets/images/gr2.svg"
                                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                                 class="lazyload mr-5 w-13" alt="">
                                            <div class="text-lg text-primary font-semibold">Satisfaction Guarantee</div>
                                        </div>
                                        <div class="text-sm leading-6 text-gray-500">
                                            <p>100% Satisfaction Guarantee. If you don’t love it, for ANY reason, we’ll
                                                take it back within 30 days and give you a full refund. </p>
                                        </div>
                                    </div>
                                    <div class="border border-gray-200 p-5 mb-5">
                                        <div class="flex items-center mb-2">
                                            <img data-src="/home_pp2_assets/images/gr3.svg"
                                                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                                 class="lazyload mr-5 w-13" alt="">
                                            <div class="text-lg text-primary font-semibold">Fast Shipping And Delivery
                                                Time
                                            </div>
                                        </div>
                                        <div class="text-sm leading-6 text-gray-500">
                                            <p>We know you want to get your order as fast as possible, so we work night
                                                and day to give you the best service possible.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /checkout -->
        <!-- cart bar -->

        <div v-if="activeProduct && formClosed" id="buy" class="order-cart-holder">
            <div class="order-cart">
                <div class="container">
                    <div class="flex items-center">
                        <div class="w-[50px] md:w-[60px]">
                            <picture>
                                <source
                                        data-srcset="/home_pp2_assets/images/cart-p.webp 1x, /home_pp2_assets/images/cart-p@2x.webp 2x"
                                        type="image/webp"
                                />
                                <source
                                        data-srcset="/home_pp2_assets/images/cart-p.png 1x, /home_pp2_assets/images/cart-p@2x.png 2x"
                                        type="image/png"
                                />
                                <img
                                        alt="" class="lazyload w-full rounded-full"
                                        data-src="/home_pp2_assets/images/cart-p.png"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                                >
                            </picture>
                        </div>
                        <div class="pl-4 mobile:hidden lg:pt-[10px]">
                            <div class="text-[#2D4990] flex items-baseline text-xs">
                                <div class="flex mr-2 lg:mr-4 space-x-[3px] text-yellow stars-smaller relative top-[.5px]">
                                    <svg class="fill-current" height="12" width="12">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
                                    </svg>
                                    <svg class="fill-current" height="12" width="12">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
                                    </svg>
                                    <svg class="fill-current" height="12" width="12">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
                                    </svg>
                                    <svg class="fill-current" height="12" width="12">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
                                    </svg>
                                    <svg class="fill-current" height="12" width="12">
                                        <use xlink:href="/home_pp2_assets/images/icons/icons.svg#star"/>
                                    </svg>
                                </div>
                                4.7 | 1,938 Customer Reviews
                            </div>
                            <div class="text-[16px] lg:text-xl font-display font-[700] text-[#2D4990]">
                                {{ activeProduct.amount }} x {{ config.title }}
                            </div>
                        </div>
                        <div class="flex items-center ml-auto">
                            <div class="lg:flex text-right items-center text-primary">
                                <del class="mr-[10px] md:mr-6 mobile:text-[16px] lg:mr-4 text-[#2D4990] opacity-[0.4] lg:top-[2px] lg:relative">
                                    {{ activeProduct.old_price | currency(config.currencySymbol) }}
                                </del>
                                <div v-if="affiliate == null" class="text-xl text-[#2D4990] font-[600] mr-[10px] md:mr-6 lg:mr-8">
                                    {{ activeProduct.price | currency(config.currencySymbol) }}<span class="hidden lg:inline-block text-base">/each</span>
                                </div>
                                <div v-if="affiliate !== null" class="mobile:text-[14px] tablet:text-[20px] text-xl font-bold mr-[10px] md:mr-6 lg:mr-8 whitespace-nowrap text-white">
                                    GET 50% OFF
                                </div>
                            </div>
                            <a v-if="affiliate == null" class="btn h-[52px] text-center px-0 w-[140px] md:w-[188px] text-[18px] scroll-to-best-seller bg-[#3467F3]" href="#bestSeller">
                                <span>Order Now</span>
                                <img alt="arrow right" class="h-[16px] mobile:ml-[10px] ml-3 mt-[0.75px] drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] relative top-[1px]" src="/home_pp2_assets/images/icons/right-arrow.svg">
                            </a>
                            <a v-if="affiliate !== null" class="btn h-[52px] text-center mobile:text-[14px] text-[18px] scroll-to-best-seller px-4 bg-[#3467F3]" href="#bestSeller">
                                <span>CLAIM DISCOUNT</span>
                                <img alt="arrow right" class="h-[16px] mobile:ml-[8px] ml-3 mt-[0.75px] drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] relative top-[1px]" src="/index_assets/images/icons/right-arrow.svg">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /cart bar -->
    </div>
</template>

<style lang="scss">
.error {
    border: 1px solid #d40f32;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v125/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.error-message {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #d40f32;
    position: absolute;
    z-index: 10;
    bottom: -20px;
    left: 15px;
}

.form-open {
    .after-app {
        display: none;
    }
}

.iti__flag {
    background-image: url("/global/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("/global/flags/flags@2x.png");
    }
}

.phone-dropdown {
    width: 100% !important;

    .form-style .form-item label {
        left: 80px
    }

    .form-style .form-item input:focus ~ .phone-dropdown label {
        opacity: 1;
    }
}

.cvv_popup_overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, .45);
    z-index: 10000;
    overflow: auto
}

.cvv_popup_overlay.open {
    display: table
}

.cvv_popup_overlay .close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    color: #000;
    z-index: 1
}

.cvv_popup_overlay .cvv_popup_wrap {
    display: table-cell;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle
}

.cvv_popup_overlay .cvv_popup_content {
    position: relative;
    margin: 0 auto;
    padding: 20px 12px;
    width: 94%;
    max-width: 380px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05)
}

.cvv_popup_overlay .cvv_popup_content h2 {
    margin-bottom: 5px;
    font-size: 1.8rem;
    font-weight: 400
}

.invalid-feedback {
    color: #f40d32;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

@media (min-width: 1024px) {
    .products-grid {
        position: relative;
        z-index: 1;
    }

    .products-grid::before,
    .products-grid::after {
        position: absolute;
        content: '';
        width: 90px;
        height: 125px;
        z-index: -10;
        background: center no-repeat url("/home_pp2_assets/images/m-cta-bg-el.svg");
        top: 16px;
    }

    .products-grid::before {
        left: -32px;
    }

    .products-grid::after {
        right: -32px;
    }
}
</style>

<script>

import 'payment';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import Paypal from "../../mixins/Paypal.vue";

const checkPhone = (value, vm) => {
    return vm.iti.isValidNumber();
}

const {
    validationMixin,
    default: Vuelidate
} = require('vuelidate')
const {
    required,
    email,
    not,
    requiredIf
} = require('vuelidate/lib/validators')
import SmoothScroll from 'smooth-scroll'


export default {
    name: 'checkout',
    mixins: [validationMixin, Paypal],
    props: ['affiliate', 'variables'],
    validations: {
        email: {
            required,
            email
        },
        first_name: {
            required
        },
        last_name: {
            required
        },
        address1: {
            required,
        },
        city: {
            required
        },
        country: {
            required
        },
        // region: {
        //     required
        // },
        zip: {
            required
        },
        state: {
            required
        },
        phone: {
            required,
            checkPhone
        },
        card_number: {
            required
        },
        card_cvc: {
            required
        },
        card_exp_month: {
            required,
            isValid: not((model) => {
                return model === '-'
            })
        },
        card_exp_year: {
            required,
            isValid: not((model) => {
                return model === '-'
            })
        },
        tos: {
            required: requiredIf(function (nestedModel) {
                return this.affiliate == null;
            })
        }

    },
    data() {
        return {
            product: null,
            fields: {
                warranty: false
            },
            success: null,
            loaded: true,
            first_name: '',
            last_name: '',
            formClosed: true,
            email: '',
            phone: '',
            phoneCorrected: '',
            address1: '',
            address2: '',
            city: '',
            country: null,
            state: '',
            zip: '',
            card_number: '',
            card_cvc: '',
            card_exp_month: '-',
            card_exp_year: '-',
            submitStatus: null,
            errors: [],
            cvc_popup: false,
            shipping: 0,
            windowWidth: window.innerWidth,
            cardBrand: null,
            paypalFunnel: false,
            upsale: null,
            showOrderSummary: false,
            showCheckoutSection: false,
            showShippingStep: false,
            notify: false,
            tos: null,
            default: false,
            w_width: ""
        }
    },
    computed: {
        config() {
            return this.$store.state.config
        },
        products() {
            return this.$store.state.products
        },
        activeProduct() {
            return this.$store.state.activeProduct
        },
        warrantyPrice() {
            return this.$store.state.warrantyPrice
        },
        warrantyState() {
            return this.$store.state.warrantyState
        },
        cardBrandClass() {
            return this.getBrandClass(this.cardBrand);
        }
    },
    created() {


        if (this.windowWidth > 968) {

            this.showOrderSummary = true;
        }

        if (this.windowWidth < 968) {
            this.mobile = true;
        }

        this.mobile = true;

        window.addEventListener('resize', this.handleResize);

        this.handleResize();

        setTimeout(() => {
            document.documentElement.style.setProperty('--text', `"${this.trans.get('offer2.scss_content_text')}"`);
        }, 100);

    },
    mounted() {


    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        country: function (val) {
            this.$eventHub.$emit('countryChange', val);
        },
        phone: function (val) {
            if (this.iti.isValidNumber()) {
                this.phoneCorrected = this.iti.getNumber()
            } else {
                this.phoneCorrected = val;
            }
        },
        shipping: function (val) {
            var shipping = parseInt(val);
            this.activeProduct.shipping = shipping;
        },
        products: function (newValue, oldValue) {
            if (this.mobile) {
                this.mobile = true;

                this.$nextTick(() => {

                    this.changeActiveProduct(this.products[1]);
                })

            }
            // this.$nextTick( () => {
            //         this.default = true;
            //         this.changeActiveProduct( this.products[1] );
            // })
        },
        'config.country': function (newv, oldv) {
            this.country = newv;
        }

    },
    methods: {

        changeWarrantyState(bool) {
            this.$store.commit("setWarrantyState", bool);
        },

        submit() {


            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log(this.$v.$invalid)
                this.submitStatus = 'ERROR'
                var invalidFields = Object.keys(this.$v.$params)
                    .filter(fieldName => this.$v[fieldName].$invalid);
                if (invalidFields) {
                    console.log(invalidFields)
                    var elmnt = document.getElementById(this.$refs[invalidFields[0]].id);
                    if (elmnt) {
                        this.$smoothScroll({
                            scrollTo: elmnt,
                            offset: -70
                        })
                    }
                }
            } else {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = null;
                    this.errors = {};
                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });

                    const navigator = window.navigator;
                    const browserData = {
                        acceptHeader: 'application/json',
                        colorDepth: screen.colorDepth,
                        javaEnabled: navigator.javaEnabled(),
                        javaScriptEnabled: true,
                        language: navigator.language,
                        screenHeight: screen.height,
                        screenWidth: screen.width,
                        timeZoneOffset: (new Date()).getTimezoneOffset().toString(),
                        userAgent: navigator.userAgent,
                    };


                    let data = {
                        'first_name': this.first_name,
                        'last_name': this.last_name,
                        'email': this.email,
                        'address1': this.address1,
                        'address2': '',
                        'city': this.city,
                        'countrylist': this.country,
                        'statelist': this.state,
                        'zip': this.zip,
                        'phone': this.phoneCorrected,
                        'card_number': this.card_number,
                        'card_cvc': this.card_cvc,
                        'card_exp': this.card_exp_month + '/' + this.card_exp_year,
                        'product': this.$store.getters.activeProduct,
                        'warranty': this.$store.getters.warrantyState,
                        'browserData': browserData
                    };


                    axios.post('/payment/submit', data).then(response => {

                        this.fields = {};
                        this.loaded = true;
                        this.success = true;
                        var data = response.data

                        let redirect = '';

                        if (data.url)
                            redirect = data.url
                        else if (data.script)
                            return eval(data.script)
                        else if (data.orderId)
                            redirect = '/upsell/' + data.orderId;
                        window.location.href = redirect;

                        // window.location.href = '/thankyou/' + data.orderId;

                        return true;

                    }).catch(error => {

                        this.success = false;
                        this.loaded = true;

                        loader.hide();

                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }

                    });
                }
            }
        },
        setCurrentProduct(product = 0) {
            this.selectedProduct = product;
        },
        setWarranty(bool) {
            // console.log(bool.target.checked)
            this.fields.warranty = bool.target.checked;
        },
        getCurrentProduct() {
            return this.$store.getters.activeProduct;
        },
        status(validation) {
            return {
                hidden: !validation.$anyError,
            }
        },
        inputStatus(validation) {
            return {
                'error': validation.$anyError,
            }
        },
        status2(validation) {
            return {
                error: validation.$anyError,
            }
        },
        showCcForm() {
            this.cc = true;

            var elmnt = document.getElementById('anchor');


            this.$smoothScroll({
                scrollTo: elmnt,
                offset: -70
            })


        },
        onBlur(e, validation) {
            if (e.target.value == '') {
                e.target.parentElement.classList.remove('focused')
            }

            validation.$touch();
        },
        onFocus(e) {
            e.target.parentElement.classList.add('focused')
        },
        getBrandClass(brand) {
            let icon = '';
            brand = Payment.fns.cardType(this.card_number) || 'unknown';

            let cardBrandToClass = {
                'visa': '/global/cards/visa.svg',
                'mastercard': '/global/cards/mastercard.svg',
                'amex': '/global/cards/amex.svg',
                'discover': '/global/cards/discover.svg',
                'dinersclub': '/global/cards/diners.svg',
                'jcb': '/global/cards/jcb.svg',
                'unknown': '/global/cards/unknown.svg',
            };
            if (cardBrandToClass[brand]) {
                icon = cardBrandToClass[brand];
            } else {
                icon = '/global/cards/unknown.svg';
            }
            ;
            return icon;
        },
        paypalUpsellYes() {
            this.fields.upsale = true;

            this.submitPaypal();
        },
        paypalUpsellNo() {
            this.submitPaypal();
        },
        pixel() {
            var items = [];

            data.items.forEach((value) => {
                items.push({
                    "id": value.actualProductId,
                    "name": value.name,
                    "quantity": value.qty,
                    "price": value.price
                })
            })

            ga('event', 'purchase', {
                "transaction_id": data.orderId,
                "value": data.totalAmount,
                "currency": data.currencyCode,
                "tax": data.salesTax,
                "shipping": data.shipTotal,
                "items": items,
            });

            return true;
        },
        submitPaypal() {
            if (this.loaded) {
                this.loaded = false;
                this.success = null;
                this.errors = {};

                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });

                this.fields.product = this.$store.getters.activeProduct;
                this.fields.warranty = this.$store.getters.warrantyState;

                axios.post('/paypal/submit', this.fields).then(response => {

                    this.fields = {};
                    this.loaded = true;
                    this.success = true;

                    window.location.href = response.data;

                }).catch(error => {

                    this.success = false;
                    this.loaded = true;

                    loader.hide();

                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }

                    alert(this.errors)
                });
            }
        },
        changeActiveProduct(product) {
            this.$store.commit("setProduct", product);
            this.askForWarranty = true;
            let launcher = $('iframe#launcher');
            if (launcher.css('bottom') !== '0px') {
                launcher.css('bottom', '0px');
            }

        },
        displayCCForm() {
            this.showCheckoutSection = true;
            document.querySelector('body').classList.add("form-open");
            $('#contact_link').text('ORDER').attr('href', '#app');
            this.formClosed = false;
            let launcher = $('iframe#launcher');
            if (launcher.css('bottom') !== '0px') {
                launcher.css('bottom', '0px');
            }

            this.$nextTick(function () {

                this.$smoothScroll({
                    scrollTo: this.$refs.checkoutSection,
                    duration: 1000,
                    offset: -50,
                })

                let legal_modal_elms = document.querySelectorAll(".agree a[href^='/page/']");

                $(legal_modal_elms).on('click', function (event) {
                    event.preventDefault()
                    console.log('click')
                    this.blur(); // Manually remove focus from clicked link.
                    $.get(this.href, function (html) {
                        $(html).appendTo('body').modal({
                            showClose: false,
                            fadeDuration: 250,
                            fadeDelay: 0.50
                        });
                    });
                });

                Payment.formatCardNumber(document.querySelector('#card_number'), 16);
                Payment.formatCardCVC(document.querySelector('#card_cvc'));

                const input = document.querySelector("#phone");

                this.iti = intlTelInput(input, {
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                    initialCountry: this.country,
                    customContainer: 'phone-dropdown',
                    allowDropdown: true,
                    formatOnDisplay: true,
                    separateDialCode: true,
                    autoFormat: true,
                    autoPlaceholder: "aggressive"
                });

            });
            // document.getElementById('footer').style.display = "none";


        },


        countTotalPrice() {
            let totalPrice
            if (!this.warrantyState) {
                totalPrice = this.activeProduct.price * this.activeProduct.amount + this.activeProduct.shipping;
            } else {
                totalPrice = (this.warrantyPrice + this.activeProduct.price) * this.activeProduct.amount + this.activeProduct.shipping;
            }
            this.$store.commit("setTotalPrice", totalPrice);
            return totalPrice
        },

        handleResize() {
            this.w_width = window.innerWidth;
            if (this.w_width < 920) {
                this.showOrderSummary = false;
            } else {
                this.showOrderSummary = true;
            }
        }
    },

}
</script>
