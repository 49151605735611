<template>
    <div>
        <div class="step-title step-2 d-flex flex-row">
            <img src="/swatch/layers.svg" width="20px" height="20px" alt="" srcset="" class="mr-3 mb-3">
            <h2 class="title">SELECT YOUR ORDER QUANTITY</h2>
        </div>
        <div id="js-widget-products" class="product-list">
            <div v-for="(product, index) in products" v-bind:key="product.id"
                 v-bind:class="[activeProduct.id === index ? 'product-list-item active' : 'product-list-item']"
                 v-on:click="changeActiveProduct(product)">
                <div class="product-list-item--header">
                    <p class="">{{ product.amount }}x {{ product.secondary_name }}</p>
                    <div class="save">Save {{ product.you_save_percent }}%</div>
                    <div v-if="product.id == 2" class="best-seller d-flex align-items-center"><img
                        src="/swatch/star.svg" height="12px" alt="" srcset=""><span>Best Seller</span></div>
                    <span class="fast-delivery d-none d-md-block"><img src="/swatch/shipping-truck.svg" height="20px"
                                                                       alt=""
                                                                       srcset=""> <span>Fast Delivery</span></span>
                </div>
                <div class="w_radio">
                    <input :id="'prod_' + index" type="radio" name="product" value="699"
                           v-bind:checked="index === activeProduct.id">
                    <i class="icon-check"></i>
                    <label class="js-unitDiscountRate" :for="'prod_' + index">
                        <div class="product-item--inner">
                            <div class="prod-image">
                                <picture>
                                    <source media="(max-width:678px)"
                                            :srcset="'/products/1-1x.webp 1x, /products/1-2x.webp 2x'"
                                            type="image/webp">
                                    <source media="(min-width:679px)"
                                            :srcset="'/products/' + product.amount + '-1x.webp 1x, /products/' + product.amount + '-2x.webp 2x'"
                                            type="image/webp">
                                    <source media="(max-width:678px)"
                                            :srcset="'/products/1-1x.png 1x, /products/1-2x.png 2x'" type="image/png">
                                    <source media="(min-width:679px)"
                                            :srcset="'/products/' + product.amount + '-1x.png 1x, /products/' + product.amount + '-2x.png 2x'"
                                            type="image/png">
                                    <img :srcset="'/products/1-1x.png 1x, /products/1-2x.png 2x'" type="image/png">

                                </picture>
                                <div class="product-amount d-block d-md-none">x{{ product.amount }}</div>
                            </div>
                            <div class="product-desc">
                                <div class="price">
                                    <span notranslate>{{ product.price | money(config.locale, config.currency) }}</span><span class="each">/each</span>
                                </div>
                                <div class="orig">Orig {{ product.old_price | money(config.locale, config.currency) }}</div>
                                <div class="total-price">
                                    <span>Total:</span><span notranslate>{{ product.price * product.amount | money(config.locale, config.currency) }}</span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <transition name="slide">
                    <div class="warranty-box" v-if="activeProduct.id === index && askForWarranty == true">
                        <div class="warranty-box--inner">
                            <div class="warranty-box-title p-2">
                                <div
                                    class="warranty-box-title--inner d-flex flex-row justify-content-start align-items-center">
                                    <div class="position-relative checkbox">
                                        <input type="checkbox" id="warrantyCb" class="mr-2"
                                               v-on:click="changeWarrantyState()" v-model="checked">
                                        <div class="position-absolute total-arrow">
                                            <img src="/swatch/arrow.svg" alt="" width="20px">
                                        </div>
                                    </div>
                                    <label for="warrantyCb"><b>Yes, I Want Lifetime Protection</b></label>
                                </div>
                            </div>
                            <p class="p-3"><span class="font-weight-bold">One Time Offer:</span> By placing your order
                                today, you can have a lifetime protection and replacement
                                warranty for only an additional <span class="warranty-price">{{
                                        activeProduct.warranty_price | money(config.locale, config.currency)
                                    }}</span> per unit.
                            </p>
                        </div>
                    </div>
                </transition>
                <div class="product-list-item--bottombar">
                    <div class="product-list-item--bottombar--inner">
                        <div class="risk">Sell Out Risk: <span>High</span></div>
                        <div class="shipping" v-if="product.shipping == 0"><strong>FREE Shipping</strong></div>
                        <div class="shipping" v-else>Fast Delivery</div>
                        <div class="discount d-none d-md-block">Discount: {{ product.you_save_percent }}%</div>
                        <div class="popular"><img height="10px" src="/swatch/signal-bars.svg" alt="">
                            {{ product.status }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Products',
    data() {
        return {
            checked: false,
            askForWarranty: false
        }
    },
    computed: {
        ...mapGetters([
            'config',
            'products',
            'activeProduct',
            'warrantyPrice',
            'warrantyState'
        ])
    },
    methods: {
        changeActiveProduct(product) {
            this.$store.commit("setProduct", product);
            this.askForWarranty = true
        },
        singleProductImage() {
            return this.$store.state.products.find(obj => {
                return obj.amount === 1
            }).img_url;
        },
        changeWarrantyState() {
            this.$store.commit("setWarrantyState", !this.warrantyState);
            this.checked = !this.checked
        },
    },
}
</script>

<style scoped>

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
    animation-timing-function: linear;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    animation-timing-function: linear;
}


.slide-enter-to, .slide-leave {
    max-height: 120px;
    overflow: hidden;
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
}
</style>
