
// store/index.js
import Vue from "vue";
import Vuex, { mapGetters } from "vuex";
import { mapActions } from 'vuex'

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        config: [],
        products: [],
        activeProduct: null,
        warrantyPrice: null,
        warrantyState: false,
        totalPrice: '',
    },

    getters: {
        activeProduct: state => {
            return state.activeProduct;
        },
        warrantyPrice: state => {
            return state.warrantyPrice;
        },
        warrantyState: state => {
            return state.warrantyState;
        },
        config: state => {
            return state.config;
        },
        products: state => {
            return state.products;
        },
        totalPrice: state => {
            return state.totalPrice;
        },
    },

    mutations: {
        setProduct(state, payload) {
            state.activeProduct = payload;
        },
        updateConfig(state, payload) {
            state.config = payload;
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        setWarrantyPrice(state, payload) {
            state.warrantyPrice = payload;
        },
        setWarrantyState(state, payload) {
            state.warrantyState = payload;
        },
        setTotalPrice(state, payload) {
            state.totalPrice = payload;
        },

    },

    methods: {
        ...mapActions([
            'getConfig', 
            'getProducts', 
            'getWarranty',
            'getTotalPrice'
        ])
    },

    actions: {
        async getConfig ( {commit} ) {
            return new Promise((resolve, reject) => {
                axios.get("/api/data.json")
                    .then(({data}) => {
                        commit("updateConfig", data);
                        resolve()
                    })
                    .catch( (error) => { 
                        reject( error )})
                });
        },

        async getProducts ({ commit, getters }) {
            return new Promise((resolve, reject) => {
                let currency = getters.config.currency
                axios.get("/api/products2.json")
                    .then( ({data}) => {
                        data.forEach( el => { 
                            el.price = el.prices[ currency ] 
                            // el.percent_off = el.percent_off[ currency ]
                            // el.shipping = el.shipping[ currency ]
                            el.konn_id = getters.config.productId
                            // el.sku = getters.config.productSku
                        });
                        return data;
                    } )
                    .then( data => {
                        commit("setProduct", data.find( p => p.status === "best seller"));
                        commit('setProducts', data)
                        resolve()

                    })
                        .catch( (error) => { 
                        reject( error )})
                });
        },

        async getWarranty ({ commit, getters }) {
            let warrantyPrice = getters.config.warrantyPrice;
            commit( 'setWarrantyPrice', warrantyPrice )
        },
    }
});