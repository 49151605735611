import Vue from 'vue'


Vue.filter('money', function (value, locale, currency) {
	if (!value) return ''
	let prepend = '';
	let digits = 2;

	if(currency == 'MXN') {
		prepend = 'Mex'
		digits = 0;
	} else if (currency == 'COP') {
		digits = 0;
	} else if (currency == 'HUF') {
		return new Intl.NumberFormat(locale, {style: 'currency', currency: 'HUF', maximumFractionDigits: 0}).format(value);
	} else if (currency == 'SEK' || currency == 'PEN') {
		digits = 0;
	} else if ( currency == 'ZAR' ) {
		return 'R' + value;
	} else if ( currency == 'MYR' ) {
		return 'RM' + value;
	} else if ( currency == 'PHP' ) {
		return '₱' + value;
	} else if ( currency == 'AUD' ) {
		prepend = 'A';
	} else if ( currency == 'CAD' ) {
		return 'C' + value.toLocaleString('en-CA', {currency: 'CAD', style: 'currency'});
	} else if ( currency == 'SGD' ) {
		return 'S' + value.toLocaleString('en-CA', {currency: 'CAD', style: 'currency'});
	} else if ( currency == 'NZD' ) {
		return 'NZ' + value.toLocaleString('en-CA', {currency: 'CAD', style: 'currency'});
	} else if ( currency == 'NOK'  || currency == 'DKK' ) {
		return new Intl.NumberFormat('sv', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0}).format(value);
	} else if ( currency == 'RON' ) {
		return value.toFixed(0) + ' Lei';
	} else if ( currency == 'TRY') {
		return value.toFixed(0) + ' TL';
	} else if ( currency == 'USD') {
		return '$' + value.toFixed(2);
	}

	return prepend + new Intl.NumberFormat(locale, { style: 'currency', currency: currency, maximumFractionDigits: digits }).format(value)
})