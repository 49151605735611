var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "relative pt-10 bg-gradient-to-b from-gray-50 to-white" },
      [
        _c("div", { staticClass: "container" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "md:grid md:grid-cols-12 md:gap-5 mobile:mt-8 mobile:flex flex-col products-grid",
            },
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                {
                  key: product.id,
                  class:
                    "md:col-span-" +
                    12 / _vm.products.length +
                    " mobile:order-2",
                  on: {
                    click: function ($event) {
                      return _vm.changeActiveProduct(product)
                    },
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "product-card",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.displayCCForm()
                        },
                      },
                    },
                    [
                      _c("input", { attrs: { type: "radio", name: "m-type" } }),
                      _vm._v(" "),
                      _vm.activeProduct.id == product.id
                        ? _c("span", {
                            staticClass:
                              "absolute -m-[1] block inset-0 border-[3px] border-primary rounded-[5px]",
                          })
                        : _c("span", {
                            staticClass:
                              "absolute -m-[1] block inset-0 hover:border-[3px] hover:border-[#8568C5] ease-in duration-75 rounded-[5px]",
                          }),
                      _vm._v(" "),
                      product.status == "best seller"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "bg-blue-500 flex items-center justify-center font-semibold absolute w-[119px] h-8 md:w-[182px] md:h-[42px] -mt-4 md:mt-[-21px] mx-auto inset-x-0 top-0 rounded-lg text-white uppercase mobile:text-xs",
                              attrs: { id: "bestSeller" },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "fill-current text-yellow mr-2 md:mr-4 mobile:w-[10px]",
                                  attrs: { width: "18", height: "18" },
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/home_pp2_assets/images/icons/icons.svg#star",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n\t\t\t\t\t\t\tBest seller\n\t\t\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeProduct.id == product.id
                        ? _c("span", { staticClass: "title active" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(product.amount) +
                                " x " +
                                _vm._s(_vm.config.title) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ])
                        : _c("span", { staticClass: "title" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(product.amount) +
                                " x " +
                                _vm._s(_vm.config.title) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "qnt" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(product.amount) +
                            "x\n\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            "data-srcset":
                              "/home_pp2_assets/images/pd" +
                              product.amount +
                              ".webp 1x, /home_pp2_assets/images/pd" +
                              product.amount +
                              "@2x.webp 2x",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width: 768px)",
                            "data-srcset":
                              "/home_pp2_assets/images/pd" +
                              product.amount +
                              ".png 1x, /home_pp2_assets/images/pd" +
                              product.amount +
                              "@2x.png 2x",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(max-width: 767px)",
                            "data-srcset":
                              "/home_pp2_assets/images/mobile-picker-img@1x.webp 1x, /home_pp2_assets/images/mobile-picker-img@2x.webp 2x",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(max-width: 767px)",
                            "data-srcset":
                              "/home_pp2_assets/images/mobile-picker-img@1x.png 1x, /home_pp2_assets/images/mobile-picker-img@2x.png 2x",
                            type: "image/png",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "lazyload img",
                          attrs: {
                            "data-src":
                              "/home_pp2_assets/images/pd" +
                              product.amount +
                              ".png",
                            alt: "",
                            src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "save" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tSave " +
                            _vm._s(product.you_save_percent) +
                            "%\n\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "price-w" }, [
                        _c("span", { staticClass: "del" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm._f("currency")(
                                  product.old_price,
                                  _vm.config.currencySymbol
                                )
                              ) +
                              "\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "current",
                            staticStyle: { "line-height": "1" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm._f("currency")(
                                    product.price,
                                    _vm.config.currencySymbol
                                  )
                                )
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-semibold text-[10px] md:text-xs lg:text-sm",
                              },
                              [_vm._v(" /each")]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mx-auto max-w-full text-center block" },
                        [
                          _c("span", { staticClass: "total" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\tTotal: " +
                                _vm._s(
                                  _vm._f("currency")(
                                    product.price * product.amount,
                                    _vm.config.currencySymbol
                                  )
                                ) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.activeProduct.id == product.id
                            ? _c(
                                "span",
                                { staticClass: "pb-4 block mobile:hidden" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn w-full",
                                      attrs: { href: "/" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.displayCCForm()
                                        },
                                      },
                                    },
                                    [_vm._v("Order Now")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activeProduct.id !== product.id
                            ? _c(
                                "span",
                                { staticClass: "pb-4 block mobile:hidden" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn w-full",
                                      attrs: { href: "/" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.displayCCForm()
                                        },
                                      },
                                    },
                                    [_vm._v("Select")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", { staticClass: "list" }, [
                            _c("span", { staticClass: "li text-[#E05554]" }, [
                              _c(
                                "svg",
                                { attrs: { width: "16", height: "16" } },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href":
                                        "/home_pp2_assets/images/icons/icons.svg#out2",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tLow Stock\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "li capitalize text-[#111619]" },
                              [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "text-lime",
                                    attrs: { width: "16", height: "16" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/home_pp2_assets/images/icons/icons.svg#star2",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(product.status) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.activeProduct.id == product.id
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "hidden mt-[10px] relative  w-full mobile:inline-block mobile:flex justify-center",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn mobile:mt-[15px] mobile-xs:max-w-[85%] mobile:max-w-[350px] mobile:w-[100%]  text-center  font-[700] text-[18px] leading-[22px] px-[30px] py-[10px]",
                                  staticStyle: {
                                    "text-shadow":
                                      "0px 2px 2px rgba(0, 0, 0, 0.25)",
                                  },
                                  attrs: { href: "/" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.displayCCForm()
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("Order Now")])]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activeProduct.id !== product.id
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "hidden mt-[10px] relative  w-full mobile:inline-block mobile:flex justify-center",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn mobile:mt-[15px] mobile-xs:max-w-[85%] mobile:max-w-[350px]  mobile:w-[100%]  text-center  font-[700] text-[18px] leading-[22px] px-[30px] py-[10px]",
                                  attrs: { href: "/" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.displayCCForm()
                                    },
                                  },
                                },
                                [_c("span", [_vm._v("Select")])]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.showCheckoutSection
      ? _c(
          "div",
          {
            ref: "checkoutSection",
            staticClass:
              "relative overflow-hidden pt-[20px] md:pt-[30px] lg:pt-[20px] pb-10 md:pb-2 lg:pb-8 checkout-section bg-[white]",
          },
          [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "lg:grid lg:grid-cols-12 lg:gap-14 border-solid border-[#E0E0E0] border-[0.5px] p-[20px] rounded-[20px]",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lg:col-span-6 lg:col-start-1 lg:col-end-7 lg:row-start-1 lg:row-end-2",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "h2 tablet:text-center mobile:text-left pb-2 md:pb-6 lg:pb-3 border-b border-gray-200 mobile:mb-3",
                        },
                        [
                          _vm._v(
                            "\n                        Shipping Details\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md:grid md:grid-cols-12 mb-8 md:mb-6 md:gap-y-3 md:gap-x-5",
                        },
                        [
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.first_name,
                                    expression: "first_name",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "first_name",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.first_name),
                                attrs: {
                                  id: "first_name",
                                  type: "text",
                                  maxlength: "64",
                                },
                                domProps: { value: _vm.first_name },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.first_name)
                                  },
                                  change: function ($event) {
                                    _vm.first_name = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("First Name")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.first_name),
                                },
                                [_vm._v("Please enter valid First Name.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.last_name,
                                    expression: "last_name",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "last_name",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.last_name),
                                attrs: {
                                  id: "last_name",
                                  type: "text",
                                  maxlength: "64",
                                },
                                domProps: { value: _vm.last_name },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.last_name)
                                  },
                                  change: function ($event) {
                                    _vm.last_name = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("Last Name")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.last_name),
                                },
                                [_vm._v("Please enter valid Last Name.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.email,
                                    expression: "email",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "email",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.email),
                                attrs: {
                                  id: "email",
                                  type: "email",
                                  inputmode: "email",
                                  maxlength: "128",
                                  required: "",
                                },
                                domProps: { value: _vm.email },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.email)
                                  },
                                  change: function ($event) {
                                    _vm.email = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("Email Address")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.email),
                                },
                                [_vm._v("Please enter valid mail address.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.phone,
                                    expression: "phone",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "phone",
                                staticClass: "form-control pb-[0.75rem]",
                                class: _vm.inputStatus(_vm.$v.phone),
                                attrs: {
                                  id: "phone",
                                  type: "text",
                                  inputmode: "tel",
                                  maxlength: "64",
                                },
                                domProps: { value: _vm.phone },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.phone)
                                  },
                                  change: function ($event) {
                                    _vm.phone = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.phone),
                                },
                                [_vm._v("Enter a valid phone number.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.address1,
                                    expression: "address1",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "address1",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.address1),
                                attrs: {
                                  id: "address1",
                                  type: "text",
                                  maxlength: "128",
                                },
                                domProps: { value: _vm.address1 },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.address1)
                                  },
                                  change: function ($event) {
                                    _vm.address1 = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "address1" } }, [
                                _vm._v("Address"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.address1),
                                },
                                [_vm._v("Please enter valid address.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.city,
                                    expression: "city",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "apt_name",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.city),
                                attrs: {
                                  id: "city",
                                  type: "text",
                                  maxlength: "128",
                                },
                                domProps: { value: _vm.city },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.city)
                                  },
                                  change: function ($event) {
                                    _vm.city = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("City")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.city),
                                },
                                [_vm._v("Enter a valid city.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.zip,
                                    expression: "zip",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "zip",
                                staticClass: "form-control",
                                class: _vm.inputStatus(_vm.$v.zip),
                                attrs: {
                                  id: "zip",
                                  type: "text",
                                  maxlength: "30",
                                },
                                domProps: { value: _vm.zip },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.zip)
                                  },
                                  change: function ($event) {
                                    _vm.zip = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("Postal Code")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.zip),
                                },
                                [_vm._v("Enter a valid zip code.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-6" }, [
                            _c(
                              "div",
                              {
                                ref: "state",
                                staticClass: "form-group",
                                attrs: { id: "state" },
                              },
                              [
                                _c("region-select", {
                                  staticClass:
                                    " form-control border rounded-md  select px-16px py-20px",
                                  class: _vm.inputStatus(_vm.$v.state),
                                  attrs: {
                                    country: _vm.country,
                                    state: _vm.state,
                                    placeholder: "Region/State",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.onFocus($event)
                                    },
                                    blur: function ($event) {
                                      return _vm.onBlur($event, _vm.$v.state)
                                    },
                                  },
                                  model: {
                                    value: _vm.state,
                                    callback: function ($$v) {
                                      _vm.state = $$v
                                    },
                                    expression: "state",
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "region" } }, [
                                  _vm._v("Region/State"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "error-message absolute",
                                    class: _vm.status(_vm.$v.state),
                                  },
                                  [_vm._v("Please select Your region.")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md:grid md:grid-cols-12 md:gap-y-3 md:gap-x-5",
                        },
                        [
                          _c("div", { staticClass: "md:col-span-12" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("img", {
                                staticClass:
                                  "fill-current absolute right-3 inset-y-0 my-auto",
                                attrs: {
                                  width: "37",
                                  height: "23",
                                  src: _vm.getBrandClass(this.cardBrand),
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.card_number,
                                    expression: "card_number",
                                  },
                                ],
                                ref: "card_number",
                                staticClass: "form-control pr-16",
                                class: _vm.inputStatus(_vm.$v.card_number),
                                attrs: {
                                  id: "card_number",
                                  type: "tel",
                                  inputmode: "numeric",
                                  autocomplete: "cc-number",
                                },
                                domProps: { value: _vm.card_number },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur(
                                      $event,
                                      _vm.$v.card_number
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.card_number = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("Card Number")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.card_number),
                                },
                                [_vm._v("This field is required.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.card_exp_month,
                                      expression: "card_exp_month",
                                    },
                                  ],
                                  ref: "card_exp_month",
                                  staticClass: "form-control",
                                  class: _vm.inputStatus(_vm.$v.card_exp_month),
                                  attrs: {
                                    required: "required",
                                    id: "month",
                                    autocomplete: "cc-exp-month",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.card_exp_month = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "-",
                                        selected: "selected",
                                        hidden: "hidden",
                                        disabled: "disabled",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "MM\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "01" } }, [
                                    _vm._v("01"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "02" } }, [
                                    _vm._v("02"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "03" } }, [
                                    _vm._v("03"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "04" } }, [
                                    _vm._v("04"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "05" } }, [
                                    _vm._v("05"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "06" } }, [
                                    _vm._v("06"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "07" } }, [
                                    _vm._v("07"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "08" } }, [
                                    _vm._v("08"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "09" } }, [
                                    _vm._v("09"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "10" } }, [
                                    _vm._v("10"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "11" } }, [
                                    _vm._v("11"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "12" } }, [
                                    _vm._v("12"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Month (MM)")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.card_exp_month),
                                },
                                [_vm._v("This field is required.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.card_exp_year,
                                      expression: "card_exp_year",
                                    },
                                  ],
                                  ref: "card_exp_year",
                                  staticClass: "form-control",
                                  class: _vm.inputStatus(_vm.$v.card_exp_year),
                                  attrs: {
                                    required: "required",
                                    id: "year",
                                    autocomplete: "cc-exp-year",
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.card_exp_year = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "-",
                                        selected: "selected",
                                        hidden: "hidden",
                                        disabled: "disabled",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "YY\n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "22" } }, [
                                    _vm._v("22"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "23" } }, [
                                    _vm._v("23"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "24" } }, [
                                    _vm._v("24"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "25" } }, [
                                    _vm._v("25"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "26" } }, [
                                    _vm._v("26"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "27" } }, [
                                    _vm._v("27"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "28" } }, [
                                    _vm._v("28"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "29" } }, [
                                    _vm._v("29"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "30" } }, [
                                    _vm._v("30"),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("label", [_vm._v("Years (YY)")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.card_exp_year),
                                },
                                [_vm._v("This field is required.")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "md:col-span-4" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.lazy",
                                    value: _vm.card_cvc,
                                    expression: "card_cvc",
                                    modifiers: { lazy: true },
                                  },
                                ],
                                ref: "apt_name",
                                staticClass: "form-control pr-12",
                                class: _vm.inputStatus(_vm.$v.card_cvc),
                                attrs: {
                                  id: "card_cvc",
                                  type: "tel",
                                  inputmode: "numeric",
                                  pattern: "[0-9]*",
                                  minlength: "3",
                                  maxlength: "4",
                                },
                                domProps: { value: _vm.card_cvc },
                                on: {
                                  focus: function ($event) {
                                    return _vm.onFocus($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.onBlur($event, _vm.$v.card_cvc)
                                  },
                                  change: function ($event) {
                                    _vm.card_cvc = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", [_vm._v("CVC")]),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "error-message",
                                  class: _vm.status(_vm.$v.card_cvc),
                                },
                                [_vm._v("This field is required.")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "absolute inset-y-0 my-auto cursor-help right-4 flex items-center",
                                  attrs: { title: "EXTRA TOOLTIP TEXT" },
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "fill-current text-gray-350",
                                      attrs: {
                                        "data-target": "#cvvModal",
                                        "data-toggle": "modal",
                                        id: "reveal",
                                        width: "18",
                                        height: "18",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.cvc_popup = !_vm.cvc_popup
                                        },
                                      },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/home_pp2_assets/images/icons/icons.svg#quest",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "cvv_popup_overlay",
                              class: [_vm.cvc_popup == true ? "open" : ""],
                              on: {
                                click: function ($event) {
                                  _vm.cvc_popup = false
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "cvv_popup_wrap" }, [
                                _c(
                                  "div",
                                  { staticClass: "cvv_popup_content" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "close",
                                        on: {
                                          click: function ($event) {
                                            _vm.cvc_popup = false
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "i",
                                          { staticClass: "material-icons" },
                                          [_vm._v("clear")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("h2", [
                                      _vm._v(
                                        "Where can I find my Security Code?"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          "The security code is a three digit number, located on the back of any\n    Visa&nbsp;<sup>®</sup>&nbsp;or MasterCard&nbsp;<sup>®</sup>&nbsp;on the right side\n    of\n    the\n    signature strip as illustrated.&nbsp;<strong> <br>Last 3 Digits of account number\n        panel:"
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _vm._m(4),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.affiliate == null
                            ? _c("div", { staticClass: "md:col-span-12" }, [
                                _c(
                                  "label",
                                  { staticClass: "checkbox mobile:mb-2" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.tos,
                                          expression: "tos",
                                        },
                                      ],
                                      attrs: {
                                        id: "tos",
                                        type: "checkbox",
                                        required: "",
                                      },
                                      domProps: {
                                        checked: Array.isArray(_vm.tos)
                                          ? _vm._i(_vm.tos, null) > -1
                                          : _vm.tos,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.tos,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.tos = $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.tos = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.tos = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm._m(5),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "invalid-feedback my-15px p-8px text-center",
                                    class: _vm.status(_vm.$v.tos),
                                  },
                                  [
                                    _vm._v(
                                      "\n                                You must agree to Terms and Conditions and Privacy Policy.\n                            "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: { hidden: _vm.success },
                          staticStyle: {
                            "background-color": "red",
                            color: "#fff",
                            "font-weight": "bold",
                          },
                          attrs: { id: "card-fields__processing-error" },
                        },
                        [
                          _vm.errors[0]
                            ? _c(
                                "p",
                                {
                                  staticStyle: {
                                    display: "block",
                                    padding: "10px",
                                    margin: "10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.errors) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn w-full",
                          on: {
                            click: function ($event) {
                              return _vm.submit()
                            },
                          },
                        },
                        [_vm._v("Complete Order")]
                      ),
                      _vm._v(" "),
                      _vm._m(6),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "h-full w-[0.5px] bg-[#E0E0E0] lg:col-start-1 lg:col-end-13 lg:row-start-1 lg:row-end-2 justify-self-center",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "lg:col-span-6 lg:col-start-7 lg:col-end-13 lg:row-start-1 lg:row-end-2 order-summary-container",
                    },
                    [
                      _c("div", { staticClass: "order-summary-holder" }, [
                        _c(
                          "div",
                          { staticClass: " order-summary-top" },
                          [
                            _c("div", {
                              staticClass:
                                "absolute inset-0 -my-24 -ml-7 mr-[-50vw] bg-gray-50 z-[-1] tablet:hidden",
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "h2 tablet:text-xl tablet:py-2 tablet:leading-8 tablet:mb-0 tablet:-mx-5 tablet:px-5 pb-3 border-b border-gray-200",
                                class: { active: _vm.showOrderSummary },
                                on: {
                                  click: function ($event) {
                                    _vm.showOrderSummary = !_vm.showOrderSummary
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                Order Summary\n                                "
                                ),
                                _c(
                                  "svg",
                                  {
                                    staticClass:
                                      "fill-current arr inline-block ml-1 lg:hidden",
                                    attrs: { width: "11", height: "6" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href":
                                          "/home_pp2_assets/images/icons/icons.svg#up",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "float-right font-sans text-sm text-gray-500 leading-8 font-medium lg:hidden",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.countTotalPrice(),
                                          _vm.config.currencySymbol
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("transition", { attrs: { name: "slide" } }, [
                              _vm.showOrderSummary
                                ? _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex border-b border-gray-200 tablet:py-4 pb-5 mb-4 flex-wrap items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-[58px] h-[58px] lg:w-[103px] mr-1 lg:mr-5 relative lg:h-[103px] bg-white border border-gray-200",
                                          },
                                          [
                                            _c("picture", [
                                              _c("source", {
                                                attrs: {
                                                  src:
                                                    "/home_pp2_assets/images/pd" +
                                                    _vm.activeProduct.amount +
                                                    ".webp 1x, /home_pp2_assets/images/pd" +
                                                    _vm.activeProduct.amount +
                                                    "-2x.webp 2x",
                                                  type: "image/webp",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("source", {
                                                attrs: {
                                                  src:
                                                    "/home_pp2_assets/images/pd" +
                                                    _vm.activeProduct.amount +
                                                    ".png 1x, /home_pp2_assets/images/pd" +
                                                    _vm.activeProduct.amount +
                                                    "-2x.png 2x",
                                                  type: "image/png",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("img", {
                                                staticClass:
                                                  "lazyload object-contain bg",
                                                attrs: {
                                                  src:
                                                    "/home_pp2_assets/images/pd" +
                                                    _vm.activeProduct.amount +
                                                    ".webp",
                                                  alt: "",
                                                },
                                              }),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-4 tablet:text-sm font-normal",
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.activeProduct.amount
                                                ) + " x"
                                              ),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.config.title) +
                                                "\n                                        "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ml-auto md:font-semibold",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.activeProduct.price,
                                                    _vm.config.currencySymbol
                                                  )
                                                ) +
                                                "/each\n                                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between items-center mb-3",
                                      },
                                      [
                                        _c("div", { staticClass: "pr-1" }, [
                                          _vm._v(
                                            "\n                                            Regular price:\n                                        "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pl-1" }, [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.activeProduct.old_price,
                                                  _vm.config.currencySymbol
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between items-center mb-3",
                                      },
                                      [
                                        _c("div", { staticClass: "pr-1" }, [
                                          _vm._v(
                                            "\n                                            Coupon Applied: "
                                          ),
                                          _c("strong", [_vm._v("MEGASAVINGS")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pl-1" }, [
                                          _vm._v(
                                            "\n                                            -" +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.activeProduct.old_price -
                                                    _vm.activeProduct.price *
                                                      _vm.activeProduct.amount,
                                                  _vm.config.currencySymbol
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between items-center mb-3",
                                      },
                                      [
                                        _c("div", { staticClass: "pr-1" }, [
                                          _vm._v("Shipping:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pl-1" }, [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.activeProduct.shipping,
                                                  _vm.config.currencySymbol
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex border-t border-gray-200 pt-3 lg:pt-5 justify-between items-center lg:mb-12 pb-5",
                                      },
                                      [
                                        _c("div", { staticClass: "pr-1" }, [
                                          _vm._v("Total:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "pl-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.countTotalPrice(),
                                                _vm.config.currencySymbol
                                              )
                                            ) +
                                              "\n                                        "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm._m(7),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.activeProduct && _vm.formClosed
      ? _c("div", { staticClass: "order-cart-holder", attrs: { id: "buy" } }, [
          _c("div", { staticClass: "order-cart" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "flex items-center" }, [
                _vm._m(8),
                _vm._v(" "),
                _c("div", { staticClass: "pl-4 mobile:hidden lg:pt-[10px]" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-[#2D4990] flex items-baseline text-xs",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex mr-2 lg:mr-4 space-x-[3px] text-yellow stars-smaller relative top-[.5px]",
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current",
                              attrs: { height: "12", width: "12" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/home_pp2_assets/images/icons/icons.svg#star",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current",
                              attrs: { height: "12", width: "12" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/home_pp2_assets/images/icons/icons.svg#star",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current",
                              attrs: { height: "12", width: "12" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/home_pp2_assets/images/icons/icons.svg#star",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current",
                              attrs: { height: "12", width: "12" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/home_pp2_assets/images/icons/icons.svg#star",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "svg",
                            {
                              staticClass: "fill-current",
                              attrs: { height: "12", width: "12" },
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    "/home_pp2_assets/images/icons/icons.svg#star",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                            4.7 | 1,938 Customer Reviews\n                        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-[16px] lg:text-xl font-display font-[700] text-[#2D4990]",
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.activeProduct.amount) +
                          " x " +
                          _vm._s(_vm.config.title) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex items-center ml-auto" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lg:flex text-right items-center text-primary",
                    },
                    [
                      _c(
                        "del",
                        {
                          staticClass:
                            "mr-[10px] md:mr-6 mobile:text-[16px] lg:mr-4 text-[#2D4990] opacity-[0.4] lg:top-[2px] lg:relative",
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeProduct.old_price,
                                  _vm.config.currencySymbol
                                )
                              ) +
                              "\n                            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.affiliate == null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-xl text-[#2D4990] font-[600] mr-[10px] md:mr-6 lg:mr-8",
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.activeProduct.price,
                                      _vm.config.currencySymbol
                                    )
                                  )
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "hidden lg:inline-block text-base",
                                },
                                [_vm._v("/each")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.affiliate !== null
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mobile:text-[14px] tablet:text-[20px] text-xl font-bold mr-[10px] md:mr-6 lg:mr-8 whitespace-nowrap text-white",
                            },
                            [
                              _vm._v(
                                "\n                                GET 50% OFF\n                            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.affiliate == null
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "btn h-[52px] text-center px-0 w-[140px] md:w-[188px] text-[18px] scroll-to-best-seller bg-[#3467F3]",
                          attrs: { href: "#bestSeller" },
                        },
                        [
                          _c("span", [_vm._v("Order Now")]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass:
                              "h-[16px] mobile:ml-[10px] ml-3 mt-[0.75px] drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] relative top-[1px]",
                            attrs: {
                              alt: "arrow right",
                              src: "/home_pp2_assets/images/icons/right-arrow.svg",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.affiliate !== null
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "btn h-[52px] text-center mobile:text-[14px] text-[18px] scroll-to-best-seller px-4 bg-[#3467F3]",
                          attrs: { href: "#bestSeller" },
                        },
                        [
                          _c("span", [_vm._v("CLAIM DISCOUNT")]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass:
                              "h-[16px] mobile:ml-[8px] ml-3 mt-[0.75px] drop-shadow-[0_1px_1px_rgba(0,0,0,0.25)] relative top-[1px]",
                            attrs: {
                              alt: "arrow right",
                              src: "/index_assets/images/icons/right-arrow.svg",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md:grid md:gap-5 md:grid-cols-12" }, [
      _c(
        "div",
        {
          staticClass:
            "md:col-span-8 md:col-start-3 lg:col-span-10 lg:col-start-2",
        },
        [
          _c("div", { staticClass: "title-b center no-desc" }, [
            _c("h2", { staticClass: "h1 pb-1 capitalize center" }, [
              _vm._v("Get yout Instant relief now!"),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "li text-[#01B157]" }, [
      _c("img", {
        staticClass:
          "absolute left-0 top-1 mobile:w-3 mobile:h-3 w-[16px] h-[16px]",
        attrs: {
          src: "/swatch/shipping-truck-green.svg",
          alt: "Free Delivery",
        },
      }),
      _vm._v("\n\t\t\t\t\t\t\t\t\tFast Delivery\n\t\t\t\t\t\t\t\t"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "h2 tablet:text-center mobile:text-left pb-2 md:pb-6 lg:pb-3 border-b border-gray-200 mobile:mb-3",
      },
      [
        _vm._v(
          "\n                        Payment Information\n                        "
        ),
        _c(
          "div",
          {
            staticClass:
              "md:hidden text-xs font-normal pt-2 text-gray-350 font-sans",
          },
          [
            _vm._v(
              "\n                            All transactions are secured and encrypted\n                        "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [
        _c("img", {
          staticClass: " b-loaded",
          attrs: {
            alt: "card",
            width: "205",
            height: "120",
            src: "/swatch/card.png",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("br"),
      _c("strong", [
        _vm._v("Visa "),
        _c("sup", [_vm._v("®")]),
        _vm._v(" and\n                                        MasterCard "),
        _c("sup", [_vm._v("®")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "check-label agree" }, [
      _vm._v("I Have read the\n                                    "),
      _c("a", { attrs: { href: "/page/terms", target: "_blank" } }, [
        _vm._v("Terms and Conditions"),
      ]),
      _vm._v(" and\n                                    "),
      _c("a", { attrs: { href: "/page/privacy", target: "_blank" } }, [
        _vm._v("Privacy Policy"),
      ]),
      _vm._v(" and agree with them.\n                                "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "py-5 flex justify-center mobile:hidden" },
      [
        _c("img", {
          staticClass: "lazyload h-12 object-contain",
          attrs: {
            "data-src": "/home_pp2_assets/images/secure.svg",
            src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tablet:hidden" }, [
      _c("div", { staticClass: "border border-gray-200 p-5 mb-5" }, [
        _c("div", { staticClass: "flex items-center mb-2" }, [
          _c("img", {
            staticClass: "lazyload mr-5 w-13",
            attrs: {
              "data-src": "/home_pp2_assets/images/gr1.svg",
              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-lg text-primary font-semibold" }, [
            _vm._v(
              "\n                                            30-Day Money Back Guarantee\n                                        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-sm leading-6 text-gray-500" }, [
          _c("p", [
            _vm._v(
              "If the item wasn’t what you’ve expected, it has been damaged or hasn’t\n                                            arrived, and 30 calendar days have not yet passed after the delivery -\n                                            please contact our Customer Support team via here, and we will help you\n                                            get a full refund ASAP!"
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border border-gray-200 p-5 mb-5" }, [
        _c("div", { staticClass: "flex items-center mb-2" }, [
          _c("img", {
            staticClass: "lazyload mr-5 w-13",
            attrs: {
              "data-src": "/home_pp2_assets/images/gr2.svg",
              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-lg text-primary font-semibold" }, [
            _vm._v("Satisfaction Guarantee"),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-sm leading-6 text-gray-500" }, [
          _c("p", [
            _vm._v(
              "100% Satisfaction Guarantee. If you don’t love it, for ANY reason, we’ll\n                                            take it back within 30 days and give you a full refund. "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "border border-gray-200 p-5 mb-5" }, [
        _c("div", { staticClass: "flex items-center mb-2" }, [
          _c("img", {
            staticClass: "lazyload mr-5 w-13",
            attrs: {
              "data-src": "/home_pp2_assets/images/gr3.svg",
              src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "text-lg text-primary font-semibold" }, [
            _vm._v(
              "Fast Shipping And Delivery\n                                            Time\n                                        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-sm leading-6 text-gray-500" }, [
          _c("p", [
            _vm._v(
              "We know you want to get your order as fast as possible, so we work night\n                                            and day to give you the best service possible."
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-[50px] md:w-[60px]" }, [
      _c("picture", [
        _c("source", {
          attrs: {
            "data-srcset":
              "/home_pp2_assets/images/cart-p.webp 1x, /home_pp2_assets/images/cart-p@2x.webp 2x",
            type: "image/webp",
          },
        }),
        _vm._v(" "),
        _c("source", {
          attrs: {
            "data-srcset":
              "/home_pp2_assets/images/cart-p.png 1x, /home_pp2_assets/images/cart-p@2x.png 2x",
            type: "image/png",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "lazyload w-full rounded-full",
          attrs: {
            alt: "",
            "data-src": "/home_pp2_assets/images/cart-p.png",
            src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }