<template>
    <div>
        <!-- <div id="js-paypal-oneclick-button" class="w_paypal paypal-process paypal paypal-list"
            v-on:click="paypalFunnel = true" v-if="config.paypal_enabled">
            <div class="w_radio">
                <input type="radio" id="paypal_method" name="paymentmethod">
                <label for="paypal_method">
                    <img src="/swatch/paypal-01.png" alt="paypal method" class=" b-loaded">
                </label>
                <i class="icon-check"></i>
            </div>
        </div>

        <div class="divider" v-if="config.paypal_enabled">
            <span class="title"> OR CHECKOUT WITH CREDIT CARD </span>
        </div> -->

        <form @submit.prevent="submit">
            <div>
                <div
                    class="step-title credit-card-title pb-4 my-4 d-flex flex-row align-items-center justify-content-between">
                    <h2 class="title">CREDIT CARD</h2>
                    <img src="/swatch/credit-cards.svg" alt="credit cards" width="190px" height="auto">
                </div>
                <div class="">
                    <div class=" form-group  inner-addon2 ">
                        <label for="card_number">Card Number</label>

                        <input id="card_number" class="creditcard-creditcardnumber" name="card_number" type="tel"
                            v-model="card_number" :class="inputStatus($v.card_number)" @focus="onFocus( $event )"
                            @blur="onBlur( $event, $v.card_number )" autocomplete="cc-number" ref="card_number"
                            data-lpignore="true">
                        <!-- <i class="fa fa-credit-card" v-bind:class="getBrandClass(this.cardBrand)" id="cc"></i> -->
                        <img :src="getBrandClass(this.cardBrand)" id="cc">
                        <span class="error-message" :class="status($v.card_number)">Invalid credit card
                            number.</span>
                    </div>
                </div>
                <div class="form-group  inner-addon2">

                    <input id="card_cvc" class="creditcard-cvv" name="card_cvc" type="tel" minlength="3"
                        v-model="card_cvc" maxlength="4" autocomplete="cc-csc" :class="inputStatus($v.card_cvc)"
                        @focus="onFocus( $event )" @blur="onBlur( $event, $v.card_cvc )" ref="card_cvc"
                        data-lpignore="true">
                    <label for="card_cvc">Card Security Code</label>
                    <i class="material-icons" id="reveal" style="" v-on:click="cvc_popup = true" notranslate>help</i>
                    <span class="error-message" :class="status($v.card_cvc)">Must comply with a valid format.</span>
                </div>
                <div class="cvv_popup_overlay" @click="cvc_popup = false"
                    v-bind:class="[cvc_popup == true ? 'open' : '']">
                    <div class="cvv_popup_wrap">
                        <div class="cvv_popup_content">
                            <span class="close" v-on:click="cvc_popup = false"><i
                                    class="material-icons">clear</i></span>
                            <h2>Where can I find my Security Code?</h2>
                            <p>The security code is a three digit number, located on the back of any
                                Visa&nbsp;<sup>®</sup>&nbsp;or MasterCard&nbsp;<sup>®</sup>&nbsp;on the right side
                                of
                                the
                                signature strip as illustrated.&nbsp;<strong> <br>Last 3 Digits of account number
                                    panel:</strong></p>
                            <p><span><img alt="card" width="205" height="120" src="/swatch/card.png"
                                        class=" b-loaded"></span>
                            </p>
                            <p><br><strong>Visa&nbsp;<sup>®</sup>&nbsp;and MasterCard&nbsp;<sup>®</sup></strong></p>
                        </div>
                    </div>
                </div>


                <div class="cvv_popup_overlay">
                    <div class="cvv_popup_wrap">
                        <div class="cvv_popup_content">
                            <span class="close"><i class="material-icons" style="font-size:24px;line-height:44px;"
                                    id="cvv_close">close</i></span>
                            <h2>Where can I find my Security Code?</h2>
                            <p>The security code is a three digit number, located on the back of any
                                Visa&nbsp;<sup>®</sup>&nbsp;or MasterCard&nbsp;<sup>®</sup>&nbsp;on the right side of
                                the
                                signature strip as illustrated.&nbsp;<strong> <br>Last 3 Digits of account number
                                    panel:</strong></p>
                            <p><span><img alt="card" width="205" height="120" src="/swatch/card.png"
                                        class=" b-loaded"></span>
                            </p>
                            <p><br><strong>Visa&nbsp;<sup>®</sup>&nbsp;and MasterCard&nbsp;<sup>®</sup></strong></p>
                        </div>
                    </div>
                </div>
            </div>

            <div data-r="true" class="wysiwyg-content expiration_date ">
                <span>Expiration:</span>
                <div class="two-column">
                    <div class="form-group">

                        <div class="custom-selectt">
                            <select name="monthddl" id="monthddl" class="form-control"
                                :class="status2($v.card_exp_month)" v-model="card_exp_month"
                                style="height:43px;font-size:16px" ref="card_exp_month">
                                <option value="-" default>Month</option>
                                <option value="01">01 (Jan)</option>
                                <option value="02">02 (Feb)</option>
                                <option value="03">03 (Mar)</option>
                                <option value="04">04 (Apr)</option>
                                <option value="05">05 (May)</option>
                                <option value="06">06 (Jun)</option>
                                <option value="07">07 (Jul)</option>
                                <option value="08">08 (Aug)</option>
                                <option value="09">09 (Sep)</option>
                                <option value="10">10 (Oct)</option>
                                <option value="11">11 (Nov)</option>
                                <option value="12">12 (Dec)</option>
                            </select>

                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-selectt">
                            <select name="yearddl" id="yearddl" class="form-control" :class="status2($v.card_exp_year)"
                                v-model="card_exp_year" style="height:43px;font-size:16px" ref="card_exp_year">
                                <option value="-" default>Year</option>

                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                                <option value="2034">2034</option>
                                <option value="2035">2035</option>
                                <option value="2036">2036</option>
                                <option value="2037">2037</option>
                                <option value="2038">2038</option>
                                <option value="2039">2039</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div>

                <div class="step-title step-2 d-flex flex-row mt-5">
                    <img src="/swatch/user.svg" width="20px" height="20px" alt="" srcset="" class="mr-3 mb-3">
                    <h2 class="title">PERSONAL DETAILS</h2>
                </div>
                <div class="full-name">
                    <div class="">

                        <div class="form-group">
                            <label for="first_name">First Name</label>
                            <input id="first_name" v-model.lazy="first_name" type="text" maxlength="30"
                                :class="inputStatus($v.first_name)" @focus="onFocus( $event )"
                                @blur="onBlur( $event, $v.first_name )" ref="first_name">
                            <span class="error-message" :class="status($v.first_name)">This field is
                                required.</span>
                        </div>

                    </div>
                    <div class="form-group">
                        <label for="last_name">Last Name</label>

                        <input id="last_name" class="shipping-lastname" v-model="last_name" type="text" maxlength="30"
                            :class="inputStatus($v.last_name)" @focus="onFocus( $event )"
                            @blur="onBlur( $event, $v.last_name )" ref="last_name">
                        <span class="error-message" :class="status($v.last_name)">This field is required.</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Email Address</label>
                    <input id="email" class="customer-email" v-model="email" type="email" maxlength="200"
                        :class="inputStatus($v.email)" @focus="onFocus( $event )" @blur="onBlur( $event, $v.email )"
                        ref="email">
                    <span class="error-message" :class="status($v.email)">Please enter valid email address</span>
                </div>

                <!-- ID for latam countries -->

                <!-- End -->
                <div class="form-group">
                    <!-- <label for="phone">Phone</label> -->

                    <input id="phone" class="customer-phone" v-model="phone" type="tel" maxlength="20"
                        :class="inputStatus($v.phone)" @focus="onFocus( $event )" @blur="onBlur( $event, $v.phone )"
                        ref="phone">

                    <span class="error-message" :class="status($v.phone)">Please enter a valid phone
                        number.</span>
                </div>


            </div>

            <div class="step-title step-2 d-flex flex-row mt-5">
                <img src="/swatch/shipping-truck-bl.svg" width="20px" height="20px" alt="" srcset="" class="mr-3 mb-3">
                <h2 class="title">SHIPPING INFORMATION</h2>
            </div>

            <div class="widget-shipping-form">
                <div class="form-group">
                    <label for="address1">Address</label>

                    <input id="address1" class="shipping-address1" v-model="address1" type="text" maxlength="80"
                        :class="inputStatus($v.address1)" @focus="onFocus( $event )"
                        @blur="onBlur( $event, $v.address1 )" ref="address1">

                    <span class="error-message" :class="status($v.address1)">This field is required.</span>
                </div>

                <div class="form-group">
                    <label for="city">City</label>

                    <input id="city" class="shipping-city" v-model="city" type="text" maxlength="30" ref="city"
                        :class="inputStatus($v.city)" @focus="onFocus( $event )" @blur="onBlur( $event, $v.city )">

                    <span class="error-message" :class="status($v.city)">This field is required.</span>
                </div>
                <div class="form-group focused">

                    <div class="custom-selectt">
                        <label for="country">Country</label>
                        <country-select id="country" v-model="country" :country="country" topCountry="US"
                            class="form-control shipping-country" :class="inputStatus($v.country)" ref="country"
                            @focus="onFocus( $event )" @blur="onBlur( $event, $v.country )" />
                    </div>
                    <span class="error-message" :class="status($v.country)">This field is required.</span>
                </div>
                <div class="two-column">
                    <div class="form-group focused">
                        <div class="custom-selectt">
                            <label for="state">State</label>
                            <region-select v-model="state" :country="country" :region="state" class="form-control"
                                ref="state" :class="inputStatus($v.state)" @focus="onFocus( $event )"
                                @blur="onBlur( $event, $v.state )" />
                        </div>
                        <span class="error-message" :class="status($v.state)">This field is
                            required.
                        </span>
                    </div>
                    <div class="form-group">
                        <label for="zip">Zip/Postcode</label>

                        <input id="zip" class="shipping-postal" type="text" maxlength="10" v-model="zip" ref="zip"
                            :class="inputStatus($v.zip)" @focus="onFocus( $event )" @blur="onBlur( $event, $v.zip )">

                        <span class="error-message" :class="status($v.zip)">This field is required.</span>
                    </div>
                </div>
            </div>

            <div id="js-basic-cta-button" class="w_button btn-order-now my-4 d-flex flex-column">
                <button type="submit">
                    <span>COMPLETE PURCHASE</span>
                </button>
                <div class="mx-auto d-flex flex-row align-items-center mt-3">
                    <img height="14px" width="auto" class="mr-2" src="/swatch/icons/lock.svg" alt="" srcset="">
                    <span style="letter-spacing: -0.5px; font-weight: 400">256-bit SSL Encrypted Checkout</span>
                </div>
            </div>
            <div v-bind:class="{ hidden: success }" id="card-fields__processing-error"
                style="background-color: red;color: #fff;font-weight: bold;">

                <p v-if="errors[0]" style="display:block;padding: 10px;margin:10px; ">
                    {{ errors }}
                </p>
            </div>
            <div class="ct_image_wrap cc-img mb-4 mt-4">
                <picture>
                    <img src="/swatch/credit-cards-btn.svg" alt="" class="no-lazy w-100">
                </picture>
            </div>
        </form>

        <!-- <div class="wrapper" id="mainBox" v-if="paypalFunnel">
                <main style="max-width: 100%!important; z-index: 999!important;">
                <div class="extra-popup custom-extra-popup golden-layout double-display" style="display: block; z-index: 999!important;">
                        <div class="wrap-scroll">
                            <div class="wrap-inner">
                                <div class="wrap-modal">
                                    <div class="head-modal">
                                    <p>
                                        <img class="visa-logo no-lazy" src="/upsells/upsell1_files/paypal-logo.png">
                                        <img class="paypal-logo no-lazy" src="/upsells/upsell1_files/paypal-logo.png"> Clients Get the Perfect Gift TODAY
                                        <strong style="display:block;">Get <u><span class="double-qty">1</span> Additional Unit</u> for Only <br class="hidden-md-up"><u><span class="savePrice">{{ '14.99' | currency(config.currencySymbol)  }}</span></u></strong></p>
                                    </div>
                                    <div class="body-modal">
                                        <div class="addition-content">
                                            <img src="products/upsell-1.png"/>
                                        </div>
                                    </div>
                                    <div class="footer-modal" data-btnaddid="btnadd" data-btncancelid="btncancel">
                                        <div class="section-button">
                                            <a class="btn-add" href="#" v-on:click="paypalUpsellYes()">YES, I Want One More for a Gift</a>
                                            <a class="btn-cancel" href="#" v-on:click="paypalUpsellNo()">No, I don't want to get an extra one for a gift</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div> -->
    </div>
</template>

<script>
    import 'payment';
    import {
        mapGetters
    } from 'vuex';

    import Validate from "../../mixins/Validate.vue";
    import Paypal from "../../mixins/Paypal.vue";
    import Card from "../../mixins/Card.vue";


    export default {
        name: 'checkout',
        mixins: [
            Validate,
            Paypal,
            Card
        ],
        props: ['config', 'affiliate'],
        data() {
            return {
                product: null,
                fields: {
                    warranty: false
                },
                success: null,
                loaded: true,
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                phoneCorrected: '',
                address1: '',
                address2: '',
                city: '',
                country: '',
                state: '',
                zip: '',
                card_number: '',
                card_cvc: '',
                card_exp_month: '-',
                card_exp_year: '-',
                submitStatus: null,
                errors: [],
                cvc_popup: false,
                cc: false,
                upsale: null,
                upsellPrice: '29.99'
            }
        },
        created() {
            this.country = this.config.country;

            this.$nextTick(function () {
                Payment.formatCardNumber(document.querySelector('#card_number'), 16);
                Payment.formatCardCVC(document.querySelector('#card_cvc'));
            })
        },
        watch: {
            country: function (val) {
                this.$eventHub.$emit('countryChange', val);
            },
            phone: function(val) {
                if (this.iti.isValidNumber()) {
                    this.phoneCorrected = this.iti.getNumber()
                } else {
                    this.phoneCorrected = val;
                }
            }
        },
        computed: {
            ...mapGetters([
                'activeProduct',
                'warrantyState'
            ])
        },
        methods: {
            submit() {

                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                    var invalidFields = Object.keys(this.$v.$params)
                        .filter(fieldName => this.$v[fieldName].$invalid);
                    if (invalidFields) {
                        var elmnt = document.getElementById(this.$refs[invalidFields[0]].id);
                        if (elmnt) {
                            this.$smoothScroll({
                                scrollTo: elmnt,
                                offset: -70
                            })
                        }
                    }
                } else {
                    if (this.loaded) {
                        this.loaded = false;
                        this.success = null;
                        this.errors = {};

                        let loader = this.$loading.show({
                            container: this.fullPage ? null : this.$refs.formContainer,
                            canCancel: false,
                        });

                        const navigator = window.navigator;
                        const browserData = {
                            acceptHeader: 'application/json',
                            colorDepth: screen.colorDepth,
                            javaEnabled: navigator.javaEnabled(),
                            javaScriptEnabled: true,
                            language: navigator.language,
                            screenHeight: screen.height,
                            screenWidth: screen.width,
                            timeZoneOffset: (new Date()).getTimezoneOffset().toString(),
                            userAgent: navigator.userAgent
                        };

                        let data = {
                            'first_name': this.first_name,
                            'last_name': this.last_name,
                            'email': this.email,
                            'address1': this.address1,
                            'address2': this.address2,
                            'city': this.city,
                            'countrylist': this.country,
                            'statelist': this.state,
                            'zip': this.zip,
                            'card_number': this.card_number,
                            'card_cvc': this.card_cvc,
                            'card_exp': this.card_exp_month + '/' + this.card_exp_year,
                            'product': this.activeProduct,
                            'warranty': this.warrantyState,
                            'browserData': browserData,
                            'phone': this.phoneCorrected,
                        };

                        axios.post('/api/payment/submit', data).then(response => {

                            this.fields = {};
                            this.loaded = true;
                            this.success = true;
                            let redirect = '';

                            if (response.data.url)
                                redirect = response.data.url
                            else if (response.data.script)
                                return eval(response.data.script)
                            else if (response.data.orderId)
                                redirect = '/upsell/' + response.data.orderId;
                            window.location.href = redirect;
                        }).catch(error => {

                            this.success = false;
                            this.loaded = true;

                            loader.hide();

                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors || {};
                            }
                        });
                    }
                }
            },
        },
    }
</script>

<style lang="scss">

.iti__flag {background-image: url("/global/flags/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("/global/flags/flags@2x.png");}
}


.phone-dropdown {
    width: 100%!important;

    .form-style .form-item label {
        left: 80px
    }

    .form-style .form-item input:focus ~ .phone-dropdown label {
        opacity: 1;
    }
}

.show-label {
    opacity:1!important;
    left: 75px!important;
    // top: 2px!important;
}

.iti__arrow {
    margin-left: 6px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
    margin-right: 3px;
}
#phone::placeholder {
    color: #9b9b9b;
}
</style>