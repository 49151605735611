var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-list", attrs: { id: "js-widget-products" } },
      _vm._l(_vm.products, function (product, index) {
        return _c(
          "div",
          {
            key: product.id,
            class: [
              _vm.activeProduct.id === index
                ? "product-list-item active"
                : "product-list-item",
            ],
            on: {
              click: function ($event) {
                return _vm.changeActiveProduct(product)
              },
            },
          },
          [
            _c("div", { staticClass: "product-list-item--header" }, [
              _c("p", {}, [
                _vm._v(
                  _vm._s(product.amount) + "x " + _vm._s(product.secondary_name)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "save" }, [
                _vm._v("Save " + _vm._s(product.you_save_percent) + "%"),
              ]),
              _vm._v(" "),
              product.id == 2
                ? _c(
                    "div",
                    { staticClass: "best-seller d-flex align-items-center" },
                    [
                      _c("img", {
                        attrs: {
                          src: "/swatch/star.svg",
                          height: "12px",
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("span", [_vm._v("Best Seller")]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(1, true),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w_radio" }, [
              _c("input", {
                attrs: {
                  id: "prod_" + index,
                  type: "radio",
                  name: "product",
                  value: "699",
                },
                domProps: { checked: index === _vm.activeProduct.id },
              }),
              _vm._v(" "),
              _c("i", { staticClass: "icon-check" }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "js-unitDiscountRate",
                  attrs: { for: "prod_" + index },
                },
                [
                  _c("div", { staticClass: "product-item--inner" }, [
                    _c("div", { staticClass: "prod-image" }, [
                      _c("picture", [
                        _c("source", {
                          attrs: {
                            media: "(max-width:678px)",
                            srcset:
                              "/products/1-1x.webp 1x, /products/1-2x.webp 2x",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width:679px)",
                            srcset:
                              "/products/" +
                              product.amount +
                              "-1x.webp 1x, /products/" +
                              product.amount +
                              "-2x.webp 2x",
                            type: "image/webp",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(max-width:678px)",
                            srcset:
                              "/products/1-1x.png 1x, /products/1-2x.png 2x",
                            type: "image/png",
                          },
                        }),
                        _vm._v(" "),
                        _c("source", {
                          attrs: {
                            media: "(min-width:679px)",
                            srcset:
                              "/products/" +
                              product.amount +
                              "-1x.png 1x, /products/" +
                              product.amount +
                              "-2x.png 2x",
                            type: "image/png",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          attrs: {
                            srcset:
                              "/products/1-1x.png 1x, /products/1-2x.png 2x",
                            type: "image/png",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "product-amount d-block d-md-none" },
                        [_vm._v("x" + _vm._s(product.amount))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "product-desc" }, [
                      _c("div", { staticClass: "price" }, [
                        _c("span", { attrs: { notranslate: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("money")(
                                product.price,
                                _vm.config.locale,
                                _vm.config.currency
                              )
                            )
                          ),
                        ]),
                        _c("span", { staticClass: "each" }, [_vm._v("/each")]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "orig" }, [
                        _vm._v(
                          "Orig " +
                            _vm._s(
                              _vm._f("money")(
                                product.old_price,
                                _vm.config.locale,
                                _vm.config.currency
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "total-price" }, [
                        _c("span", [_vm._v("Total:")]),
                        _c("span", { attrs: { notranslate: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("money")(
                                product.price * product.amount,
                                _vm.config.locale,
                                _vm.config.currency
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "slide" } }, [
              _vm.activeProduct.id === index && _vm.askForWarranty == true
                ? _c("div", { staticClass: "warranty-box" }, [
                    _c("div", { staticClass: "warranty-box--inner" }, [
                      _c("div", { staticClass: "warranty-box-title p-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "warranty-box-title--inner d-flex flex-row justify-content-start align-items-center",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "position-relative checkbox" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checked,
                                      expression: "checked",
                                    },
                                  ],
                                  staticClass: "mr-2",
                                  attrs: { type: "checkbox", id: "warrantyCb" },
                                  domProps: {
                                    checked: Array.isArray(_vm.checked)
                                      ? _vm._i(_vm.checked, null) > -1
                                      : _vm.checked,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeWarrantyState()
                                    },
                                    change: function ($event) {
                                      var $$a = _vm.checked,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.checked = $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.checked = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.checked = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "position-absolute total-arrow",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/swatch/arrow.svg",
                                        alt: "",
                                        width: "20px",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "warrantyCb" } }, [
                              _c("b", [
                                _vm._v("Yes, I Want Lifetime Protection"),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "p-3" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("One Time Offer:"),
                        ]),
                        _vm._v(
                          " By placing your order\n                            today, you can have a lifetime protection and replacement\n                            warranty for only an additional "
                        ),
                        _c("span", { staticClass: "warranty-price" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("money")(
                                _vm.activeProduct.warranty_price,
                                _vm.config.locale,
                                _vm.config.currency
                              )
                            )
                          ),
                        ]),
                        _vm._v(" per unit.\n                        "),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "product-list-item--bottombar" }, [
              _c(
                "div",
                { staticClass: "product-list-item--bottombar--inner" },
                [
                  _vm._m(2, true),
                  _vm._v(" "),
                  product.shipping == 0
                    ? _c("div", { staticClass: "shipping" }, [
                        _c("strong", [_vm._v("FREE Shipping")]),
                      ])
                    : _c("div", { staticClass: "shipping" }, [
                        _vm._v("Fast Delivery"),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "discount d-none d-md-block" }, [
                    _vm._v(
                      "Discount: " + _vm._s(product.you_save_percent) + "%"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "popular" }, [
                    _c("img", {
                      attrs: {
                        height: "10px",
                        src: "/swatch/signal-bars.svg",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(product.status) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step-title step-2 d-flex flex-row" }, [
      _c("img", {
        staticClass: "mr-3 mb-3",
        attrs: {
          src: "/swatch/layers.svg",
          width: "20px",
          height: "20px",
          alt: "",
          srcset: "",
        },
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "title" }, [
        _vm._v("SELECT YOUR ORDER QUANTITY"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fast-delivery d-none d-md-block" }, [
      _c("img", {
        attrs: {
          src: "/swatch/shipping-truck.svg",
          height: "20px",
          alt: "",
          srcset: "",
        },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Fast Delivery")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risk" }, [
      _vm._v("Sell Out Risk: "),
      _c("span", [_vm._v("High")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }