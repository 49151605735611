<script>
import "payment";

export default {
  data() {
    return {
      cardBrand: null
    }
  },  
  created() {
    this.$nextTick(function() {
      Payment.formatCardNumber(document.querySelector("#card_number"), 16);
      Payment.formatCardCVC(document.querySelector("#card_cvc"));
    });
  },
  computed: {
    cardBrandClass() {
      return this.getBrandClass(this.cardBrand);
    }
  },
  methods: {
    getBrandClass(brand) {
      let icon = "";
      brand = Payment.fns.cardType(this.card_number) || "unknown";

      let cardBrandToClass = {
        visa: "/global/cards/visa.svg",
        mastercard: "/global/cards/mastercard.svg",
        amex: "/global/cards/amex.svg",
        discover: "/global/cards/discover.svg",
        dinersclub: "/global/cards/diners.svg",
        jcb: "/global/cards/jcb.svg",
        unknown: "/global/cards/unknown.svg"
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      } else {
        icon = "/global/cards/unknown.svg";
      }
      return icon;
    }
  }
};
</script>
