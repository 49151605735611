<template>
<div>
<main>


<div class="wrapper">
      <header>
        <div class="container">

              <img :src="config.logo">
            </div>
            <!-- <div class="promo-text">Watch, Phone, and Health Monitoring All in One</div> -->
      </header>
      <main>
        <section class="section-1">
          <div class="container">
            <div class="one-column">
              <div data-r="" class="wysiwyg-content heading-title ">
                <h2>We Understand Accidents Happen! <img src="/new/upsell/upsell2_files/cards-n.png" class="cc-img no-lazy"><img src="/new/upsell/upsell2_files/paypal-n.png" class="pp-img no-lazy"> customers can get Lifetime Warranty & Protection Today!</h2>
              </div>
            </div>
          </div> <!--/container-->
        </section> <!-- /row-wrapper-->
        <section class="section-2">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="one-column"> </div>
              </div> <!--/span-->
              <div class="col-md-6">
                <div class="one-column"> </div>
              </div> <!--/span-->
            </div> <!--/row-->
          </div> <!--/container-->
        </section> <!-- /row-wrapper-->
        <section class="section-3">
          <div class="container">
            <div class="one-column">
              <div class="editor warranty-terms">
                <div class="top-title">
                  <p><img src="/new/upsell/upsell2_files/warranty-n.png" alt="warranty" class="no-lazy"></p>
                  <h2>AS A <img src="/new/upsell/upsell2_files/cards-n.png" class="cc-img no-lazy"><img src="/new/upsell/upsell2_files/paypal-n.png" class="pp-img no-lazy"> CUSTOMER YOUR <span style="color: #fdf568;">Lifetime Warranty</span> AND EXTENDED PROTECTION GIVES YOU THIS COVERAGE:</h2>
                </div>
                <div class="content">
                  <ul>
                    <li><strong>DEFECTIVE</strong> - If you find any product you receive from your order today is defective, we'll replace it with a brand new one!</li>
                    <li><strong>SCRATCHES</strong> - If any product you receive from your order today is ever scratched, we'll replace it with a brand new one!</li>
                    <li><strong>DENTS</strong> - If any product you receive from your order today ever experiences a dent, we'll replace the product with a brand new one!</li>
                    <li><strong>WATER DAMAGE</strong> - If any product you receive from your order today ever experiences water damage, we'll replace the product with a brand new one!</li>
                    <li><strong>INTERNAL COMPONENT DAMAGE</strong> - If at any time you experience any internal component damage, we'll replace the product with a brand new one! 100%</li>
                    <li><strong>REPLACEMENT</strong> - Any product purchased today will fall under the Lifetime Warranty and will be replaced with a brand new one!</li>
                    <li><strong>LOSS OR THEFT</strong> - Under the Lifetime Warranty, we will cover you for loss or theft! Your satisfaction is our top priority!</li>
                  </ul>
                  <h3><span style="color: #cc0000;">Special Price: <strong><span class="spanUpsellPrice">{{ fields.price | money(config.locale, config.currency) }} / each</span></strong></span></h3>
                </div>
                <div class="js-btn-place-upsell-order" v-on:click="submit()">
                  <p><img class="no-lazy" src="/new/upsell/upsell2_files/button-check-n.png" alt="button_check">Yes, Protect My Order</p>
                </div>
                <div class="js-btn-no-thanks" v-on:click="nothanks()">
                  <p><span style="color: #1a0dab;">No thanks, I would not like to be covered today</span></p>
                </div>
              </div>
            </div>
          </div> <!--/container-->
        </section> <!-- /row-wrapper-->
        <section class="section-4">
          <div class="container">
          </div> <!--/container-->
        </section> <!-- /row-wrapper-->
      </main>
    </div>
    </main>
  </div>
</template>


<script>
    export default {
        name: 'Upsell',
        props: ['orderData', 'config'],
        data: function () {
            return {
                fields: {
                    konn_id: this.config.warrantyId,
                    price:  this.config.warrantyPrice,
                    quantity: 1,
                    sku: 'lifetime-warranty',
                    secondary_name: 'Extended Warranty',
                    upsell: '2'
                },
                errors: {},
                success: null,
                loaded: true,
            };
        },
        created: function() {
          let amount = this.orderData.items[0].qty;
          this.fields.quantity = amount;
        },
        methods: {
            calculatePrice(quantity) {
                let price = 24.99;
                switch (quantity) {
                    case 1:
                        price = 24.99
                        break;
                    case 2:
                        price = 22.49
                        break;
                    case 3:
                        price = 19.99
                        break;
                    case 4:
                        price = 17.49
                        break;
                    case 5:
                        price = 14.99
                        break;
                    default:
                        price = 24.99
                }

                return price;
            },
            quantityAdd() {
                if (this.fields.quantity < 5) {
                    this.fields.quantity++;

                    this.fields.price = this.calculatePrice(this.fields.quantity)
                }
            },
            quantityRemove() {
                if (this.fields.quantity > 1) {
                    this.fields.quantity--;

                    this.fields.price = this.calculatePrice(this.fields.quantity)
                }
            },
            submit() {
                if (this.loaded) {
                    this.loaded = false;
                    this.success = null;
                    this.errors = {};

                    let loader = this.$loading.show({
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                    });


                    axios.post('/upsell/submit', this.fields).then(response => {

                        this.fields = {};
                        this.loaded = true;
                        this.success = true;
                        console.log(response)


                        window.location.href = '/thankyou/' + response.data.orderId;

                    }).catch(error => {

                        this.success = false;
                        this.loaded = true;

                        loader.hide();

                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors || {};
                        }

                        alert(this.errors)

                        window.location.href = '/thankyou/' + this.orderData.orderId;

                    });
                }
            },
            nothanks() {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });

                window.location.href = '/thankyou/' + this.orderData.orderId;
            }
        }

    }

</script>
