var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.activeProduct
    ? _c("div", {}, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row align-items-center justify-content-between py-2",
          },
          [
            _c("span", {}, [
              _vm._v(
                _vm._s(this.activeProduct.secondary_name) +
                  " x " +
                  _vm._s(this.activeProduct.amount) +
                  "   "
              ),
            ]),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("money")(
                      this.activeProduct.price * this.activeProduct.amount,
                      _vm.config.locale,
                      _vm.config.currency
                    )
                  )
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        this.activeProduct.shipping != 0
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center justify-content-between pt-1 pb-2",
              },
              [
                _c("span", {}, [_vm._v("Shipping:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        this.activeProduct.shipping,
                        _vm.config.locale,
                        _vm.config.currency
                      )
                    )
                  ),
                ]),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center justify-content-between pt-1 pb-2",
              },
              [
                _c("span", {}, [_vm._v("Shipping:")]),
                _vm._v(" "),
                _c("strong", { staticStyle: { color: "green" } }, [
                  _vm._v("FREE"),
                ]),
              ]
            ),
        _vm._v(" "),
        this.warrantyState
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center justify-content-between pb-2 ",
              },
              [
                _c("span", {}, [_vm._v("Warranty:")]),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("money")(
                        this.activeProduct.warranty_price *
                          this.activeProduct.amount,
                        _vm.config.locale,
                        _vm.config.currency
                      )
                    )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-row align-items-center justify-content-between border-top py-2",
          },
          [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v("Today's Total: "),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm._f("money")(
                    _vm.countTotalPrice(),
                    _vm.config.locale,
                    _vm.config.currency
                  )
                )
              ),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-column" }, [
      _c(
        "div",
        {
          staticClass:
            "step-title step-2 d-flex flex-row align-items-end mb-0 pb-3",
        },
        [
          _c("img", {
            staticClass: "mr-2 d-none d-md-block",
            attrs: {
              src: "/swatch/order.svg",
              width: "20px",
              height: "auto",
              alt: "",
              srcset: "",
            },
          }),
          _vm._v(" "),
          _c("h2", { staticClass: "title mb-0" }, [_vm._v("ORDER SUMMARY")]),
          _c("span", { staticClass: "font-weight-bold ml-auto" }, [
            _vm._v("Price"),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }