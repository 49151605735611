
import Vue from "vue";
import Lang from 'lang.js';

const messages = window.messages;

const default_locale = window.default_locale;
const fallback_locale = window.fallback_locale;

Vue.prototype.trans = new Lang( { messages, locale: default_locale, fallback: fallback_locale } );

Vue.prototype.$eventHub = new Vue(); // Global event bus

