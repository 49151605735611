<script>
export default {
    data() {
        return {
            paypal: {
                popup: false,
                upsale: false
            }
        }
    },
    methods: {

        paypalUpsellYes() {
            this.paypal.upsale = true;

            this.submitPaypal();
        },
        paypalUpsellNo() {
            this.submitPaypal();
        },
        submitPaypal() {
            if (this.loaded) {
                this.loaded = false;
                this.success = null;
                this.errors = {};

                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                });

                this.fields.product = this.activeProduct();
                this.fields.warranty = this.warrantyState();

                axios.post('/paypal/submit', this.fields).then(response => {

                    this.fields = {};
                    this.loaded = true;
                    this.success = true;

                    window.location.href = response.data;

                }).catch(error => {

                    this.success = false;
                    this.loaded = true;

                    loader.hide();

                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }

                    alert(this.errors)
                });
            }
        },
    }
}
</script>
