require('./services/bootstrap');
import Vue from "vue";
import Loading from 'vue-loading-overlay';
import vueCountryRegionSelect from 'vue-country-region-select'
import Vue2Filters from 'vue2-filters'
import vueSmoothScroll from 'vue2-smooth-scroll'

import store from "./services/store_home_checkout"; // global var storage
import './filters/Money'
import './services/lang'

import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.use(vueCountryRegionSelect);
Vue.use(Vue2Filters);
Vue.use(vueSmoothScroll)

Vue.prototype.$eventHub = new Vue(); // Global event bus

import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);
Vue.use(vueCountryRegionSelect);
Vue.use(Vue2Filters);
Vue.use(vueSmoothScroll)

import warr from './components/Checkout/warr.vue';
import CheckoutComponent from './components/Checkout/Checkout.vue';
import ProductsComponent from './components/Checkout/Products.vue';
import Total from './components/Checkout/Total.vue';
import CheckoutComponent2 from './components/Checkout/Checkout2.vue';

Vue.component('checkout-form', CheckoutComponent);
Vue.component('checkout-form2', CheckoutComponent2);
Vue.component('products-list', ProductsComponent);
Vue.component('total', Total);
Vue.component('warr', warr);

const app = new Vue({
	el: '#app',
	store,
    components: {
        Vue2Filters
	},
	created() {
		store.dispatch('getConfig').then(() => {
			
			store.dispatch('getProducts').then(() => {
				store.dispatch('getWarranty')
			});
		})
	}
});
