<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';


const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength, email, not } = require("vuelidate/lib/validators");

const checkPhone = (value, vm) => { 
  return vm.iti.isValidNumber();
}


export default {
  mixins: [validationMixin],
  validations: {
    card_number: {
      required
    },
    card_cvc: {
      required
    },
    card_exp_month: {
      required,
      isValid: not(model => {
        return model === "-";
      })
    },
    card_exp_year: {
      required,
      isValid: not(model => {
        return model === "-";
      })
    },
    first_name: {
      required
    },
    last_name: {
      required
    },
    email: {
      required,
      email
    },
    phone: {
      required,
      checkPhone
    },
    address1: {
      required
    },
    city: {
      required
    },
    country: {
      required
    },
    zip: {
      required
    },
    state: {
      required
    }
  },
  data() {
    return {
      paypal: {
        popup: false,
        upsale: false,
        iti: null,
      }
    };
  },
  mounted() {
      const input = document.querySelector("#phone");

      this.iti = intlTelInput(input, {
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
          initialCountry: this.country,
          customContainer: 'phone-dropdown',
          allowDropdown: true,
          formatOnDisplay: true,
          separateDialCode: true,
          autoFormat: true,
          autoPlaceholder: "aggressive"
      });   
  },
  methods: {
    status(validation) {
      return {
        hidden: !validation.$anyError
      };
    },
    inputStatus(validation) {
      return {
        error: validation.$anyError
      };
    },
    status2(validation) {
      return {
        error: validation.$anyError
      };
    },
    onBlur(e, validation) {
      if (e.target.value == "") {
        e.target.parentElement.classList.remove("focused");
      }

      validation.$touch();
    },
    onFocus(e) {
      e.target.parentElement.classList.add("focused");
    }
  }
};
</script>
