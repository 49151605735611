<template>
    <div class="" v-if="this.activeProduct">
        <div class="d-flex flex-column">
            <div class="step-title step-2 d-flex flex-row align-items-end mb-0 pb-3">
                <img src="/swatch/order.svg" width="20px" height="auto" alt="" srcset="" class="mr-2 d-none d-md-block" > <h2 class="title mb-0">ORDER SUMMARY</h2><span class="font-weight-bold ml-auto">Price</span></div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between py-2"> <span class="">{{ this.activeProduct.secondary_name }} x {{ this.activeProduct.amount }}   </span> <span> {{ this.activeProduct.price * this.activeProduct.amount | money(config.locale, config.currency)  }}</span></div>
            <div class="d-flex flex-row align-items-center justify-content-between pt-1 pb-2" v-if="this.activeProduct.shipping != 0"> <span class="">Shipping:</span> <span>{{ this.activeProduct.shipping | money(config.locale, config.currency)  }}</span></div>
            <div class="d-flex flex-row align-items-center justify-content-between pt-1 pb-2" v-else> <span class="">Shipping:</span> <strong style="color: green;">FREE</strong></div>
            <div v-if="this.warrantyState" class="d-flex flex-row align-items-center justify-content-between pb-2 "> <span class="">Warranty:</span> <span>{{  this.activeProduct.warranty_price * this.activeProduct.amount | money(config.locale, config.currency)  }}</span></div>
            <div class="d-flex flex-row align-items-center justify-content-between border-top py-2"> <span class="font-weight-bold">Today's Total: </span> 
                <span class="font-weight-bold">{{  countTotalPrice() | money(config.locale, config.currency)  }}</span>
            </div>
        </div> 
    </div>
</template>

<style scoped>  

</style>


<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'total',
        computed: {
            ...mapGetters([
                'config',
                'products',
                'activeProduct',
                'warrantyPrice',
                'warrantyState'
            ])
        },
        methods: {
            changeWarrantyState() {
                this.$store.commit("setWarrantyState", !this.warrantyState);
            },
            countTotalPrice() {
                let totalPrice
                if ( !this.warrantyState ) {
                    totalPrice = this.activeProduct.price * this.activeProduct.amount + this.activeProduct.shipping;
                } else {
                    totalPrice = this.activeProduct.price * this.activeProduct.amount + this.activeProduct.warranty_price * this.activeProduct.amount + this.activeProduct.shipping;
                }
                this.$store.commit("setTotalPrice", totalPrice);
                return totalPrice
            },
        },
    }

</script>



