var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("main", [
      _c("div", { staticClass: "wrapper" }, [
        _c("header", [
          _c("div", { staticClass: "container" }, [
            _c("img", { attrs: { src: _vm.config.logo } }),
          ]),
        ]),
        _vm._v(" "),
        _c("main", [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("section", { staticClass: "section-3" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "one-column" }, [
                _c("div", { staticClass: "editor warranty-terms" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { staticClass: "content" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("h3", [
                      _c("span", { staticStyle: { color: "#cc0000" } }, [
                        _vm._v("Special Price: "),
                        _c("strong", [
                          _c("span", { staticClass: "spanUpsellPrice" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("money")(
                                  _vm.fields.price,
                                  _vm.config.locale,
                                  _vm.config.currency
                                )
                              ) + " / each"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "js-btn-place-upsell-order",
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._m(4)]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "js-btn-no-thanks",
                      on: {
                        click: function ($event) {
                          return _vm.nothanks()
                        },
                      },
                    },
                    [_vm._m(5)]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(6),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section-1" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "one-column" }, [
          _c(
            "div",
            {
              staticClass: "wysiwyg-content heading-title ",
              attrs: { "data-r": "" },
            },
            [
              _c("h2", [
                _vm._v("We Understand Accidents Happen! "),
                _c("img", {
                  staticClass: "cc-img no-lazy",
                  attrs: { src: "/new/upsell/upsell2_files/cards-n.png" },
                }),
                _c("img", {
                  staticClass: "pp-img no-lazy",
                  attrs: { src: "/new/upsell/upsell2_files/paypal-n.png" },
                }),
                _vm._v(
                  " customers can get Lifetime Warranty & Protection Today!"
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section-2" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "one-column" }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "one-column" }),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-title" }, [
      _c("p", [
        _c("img", {
          staticClass: "no-lazy",
          attrs: {
            src: "/new/upsell/upsell2_files/warranty-n.png",
            alt: "warranty",
          },
        }),
      ]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("AS A "),
        _c("img", {
          staticClass: "cc-img no-lazy",
          attrs: { src: "/new/upsell/upsell2_files/cards-n.png" },
        }),
        _c("img", {
          staticClass: "pp-img no-lazy",
          attrs: { src: "/new/upsell/upsell2_files/paypal-n.png" },
        }),
        _vm._v(" CUSTOMER YOUR "),
        _c("span", { staticStyle: { color: "#fdf568" } }, [
          _vm._v("Lifetime Warranty"),
        ]),
        _vm._v(" AND EXTENDED PROTECTION GIVES YOU THIS COVERAGE:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("strong", [_vm._v("DEFECTIVE")]),
        _vm._v(
          " - If you find any product you receive from your order today is defective, we'll replace it with a brand new one!"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("SCRATCHES")]),
        _vm._v(
          " - If any product you receive from your order today is ever scratched, we'll replace it with a brand new one!"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("DENTS")]),
        _vm._v(
          " - If any product you receive from your order today ever experiences a dent, we'll replace the product with a brand new one!"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("WATER DAMAGE")]),
        _vm._v(
          " - If any product you receive from your order today ever experiences water damage, we'll replace the product with a brand new one!"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("INTERNAL COMPONENT DAMAGE")]),
        _vm._v(
          " - If at any time you experience any internal component damage, we'll replace the product with a brand new one! 100%"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("REPLACEMENT")]),
        _vm._v(
          " - Any product purchased today will fall under the Lifetime Warranty and will be replaced with a brand new one!"
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("strong", [_vm._v("LOSS OR THEFT")]),
        _vm._v(
          " - Under the Lifetime Warranty, we will cover you for loss or theft! Your satisfaction is our top priority!"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticClass: "no-lazy",
        attrs: {
          src: "/new/upsell/upsell2_files/button-check-n.png",
          alt: "button_check",
        },
      }),
      _vm._v("Yes, Protect My Order"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticStyle: { color: "#1a0dab" } }, [
        _vm._v("No thanks, I would not like to be covered today"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section-4" }, [
      _c("div", { staticClass: "container" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }